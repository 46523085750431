import {
  BlockStack,
  LegacyCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from '@shopify/polaris'

export const DesignSkeleton = ({
  leftSkeletonNum = 4,
  rightSkeletonNum = 12,
}: {
  leftSkeletonNum?: number
  rightSkeletonNum?: number
}) => {
  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="w-full lg:flex-[0_1_440px]">
        {new Array(leftSkeletonNum).fill(0).map((_, index) => (
          <LegacyCard sectioned key={index}>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={4} />
            </TextContainer>
          </LegacyCard>
        ))}
      </div>

      <div className="w-full lg:flex-[0_1_440px]">
        <LegacyCard>
          <LegacyCard.Section>
            <TextContainer>
              <BlockStack gap="200">
                <SkeletonDisplayText size="small" />
                <SkeletonDisplayText size="small" maxWidth="100%" />
                {new Array(rightSkeletonNum).fill(0).map((_, index) => (
                  <SkeletonBodyText key={index} />
                ))}
              </BlockStack>
            </TextContainer>
          </LegacyCard.Section>
        </LegacyCard>
      </div>
    </div>
  )
}
