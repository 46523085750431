import BillingApi from '@/api/billing'
import i18n from '@/i18n'
import {
  LegacyCard,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from '@shopify/polaris'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/billing/')({
  loader: async () => {
    i18n.loadNamespaces('billing')
    const { data } = await BillingApi.getSubscribePlan()
    return data
  },
  pendingComponent: BillingSkeleton,
})

export function BillingSkeleton() {
  return (
    <Page>
      {new Array(4).fill(0).map((_, index) => (
        <LegacyCard sectioned key={index}>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={8} />
          </TextContainer>
        </LegacyCard>
      ))}
    </Page>
  )
}
