import type { Validator } from '@shopify/react-form'

export function requiredBy(enable: boolean, validate: Validator<any, any>) {
  return (value: any, ctx: any) => {
    if (enable) {
      return validate(value, ctx)
    }
    return undefined
  }
}
