import type {
  SelCouponsByIdResponse,
  SelEventCouponResponse,
  SelEventsMarketingByIdResponse,
  SelEventsMarketingResponse,
} from '@/api/types'
import client from '@/lib/request'

export default {
  // Events
  addEvents(param = {}) {
    return client.post('addEventsMarketing', param)
  },
  selEvents(param: {
    event_name: string
    currentPage: number
    eachPageNum: number
  }) {
    return client.post<SelEventsMarketingResponse>('selEventsMarketing', param)
  },
  updEvents(param = {}) {
    return client.post('updEventsMarketing', param)
  },
  selEventsById(param: { id: number }) {
    return client.post<SelEventsMarketingByIdResponse>(
      'selEventsMarketingById',
      param,
    )
  },
  selEventsByName(param = {}) {
    return client.post('selEventsMarketingByName', param)
  },
  delEvents(param = {}) {
    return client.post('delEventsMarketing', param)
  },
  updActionStatus(param = {}) {
    return client.post('updActionStatus', param)
  },

  // Coupons
  selEventsNameAndCollection(param = {}) {
    return client.post('selEventNameAndCollection', param)
  },
  addCoupon(param = {}) {
    return client.post('addEventCoupon', param)
  },
  selCoupons(param: {
    currentPage: number
    eachPageNum: number
    event_id: number
  }) {
    return client.post<SelEventCouponResponse>('selEventCoupon', param)
  },
  selCouponsById(param = {}) {
    return client.post<SelCouponsByIdResponse>('selEventCouponById', param)
  },
  updCoupons(param = {}) {
    return client.post('updEventCoupon', param)
  },
  delCoupons(param = {}) {
    return client.post('delEventCoupon', param)
  },
  updActionStatusCoupon(param = {}) {
    return client.post('updActionStatusCoupon', param)
  },
  selCouponsByName(param = {}) {
    return client.post('selEventCouponByName', param)
  },
  selCouponsValid(param = {}) {
    return client.post('selEventCouponValid', param)
  },
}
