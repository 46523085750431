import { ModuleCard } from '@/components/ui/card/ModuleCard'
import {
  BlockStack,
  Button,
  Divider,
  InlineStack,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import Earn from '@/assets/icons/Earn.svg'
import RewardGift from '@/assets/icons/RewardGift.svg'
import Referral from '@/assets/icons/Referral.svg'
import Diamond from '@/assets/icons/Diamond.svg'
import Design from '@/assets/icons/Design.svg'

export const QuickGuide = () => {
  const { t: homeT } = useTranslation('home')
  const quickList = [
    {
      title: homeT('setUpPoints'),
      desc: homeT('increaseLifetimeValue'),
      iconName: Earn,
      path: '/program?type=points',
    },
    {
      title: homeT('setUpRewards'),
      desc: homeT('incentivizeRepeatPurchases'),
      iconName: RewardGift,
      path: '/program?type=redeem',
    },
    {
      title: homeT('setUpReferrals'),
      desc: homeT('rewardReferrals'),
      iconName: Referral,
      path: '/program/referrals',
    },
    {
      title: homeT('setUpVIPProgram'),
      desc: homeT('incentivizeCustomerSpending'),
      iconName: Diamond,
      path: '/program/vip',
    },
    {
      title: homeT('setUpBrand'),
      desc: homeT('customizeWidget'),
      iconName: Design,
      path: '/brand-style',
    },
  ]
  return (
    <ModuleCard title={homeT('quickGuide')}>
      {quickList.map((item, index) => (
        <BlockStack key={index} gap={'400'}>
          <InlineStack blockAlign="center" align="space-between">
            <InlineStack blockAlign="center" gap={'400'}>
              <div className="flex h-12 w-12 items-center justify-center rounded-200 bg-[#F6F6F7] p-2">
                <img className="h-6 w-6" src={item.iconName} alt="" />
              </div>
              <BlockStack>
                <Text as="p">
                  <span className="text-[14px] font-[650]">{item.title}</span>
                </Text>
                <Text as="p" tone="subdued">
                  {item.desc}
                </Text>
              </BlockStack>
            </InlineStack>
            <Button url={item.path}>{homeT('setup')}</Button>
          </InlineStack>
          {index !== quickList.length - 1 && <Divider></Divider>}
        </BlockStack>
      ))}
    </ModuleCard>
  )
}
