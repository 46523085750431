import client from '@/lib/request'
import type {
  AddLanguageType,
  LanguageSettingType,
  ThemeDataType,
} from './types/themeType'
import type { SingleLanguageSetting } from './types/translate'
export default {
  // 主题信息查询
  selThemeTemplate(param = {}) {
    return client.post<ThemeDataType>('selThemeTemplate', param)
  },
  // 更新主题信息
  updThemeTemplate(param = {}) {
    return client.post('updThemeTemplate', param)
  },
  // 获取vip等级列表信息
  getVipTierList(param = {}) {
    return client.post('getVipTierList', param)
  },

  getFooterShowStatus(param = {}) {
    return client.post('getFooterShowStatus', param)
  },

  // 已添加的语言
  selLanguageSetting(param = {}) {
    return client.post<LanguageSettingType>('selLanguageSetting', param)
  },
  // 弹框初始化数据
  addLanguage(param = {}) {
    return client.post<AddLanguageType>('addLanguage', param)
  },
  // 删除已添加语言
  delLanguage(param = {}) {
    return client.post('delLanguage', param)
  },

  // 编辑语言页面的自定义接口
  // 查询详情接口
  selSingleLanguageSetting(param = {}) {
    return client.post<SingleLanguageSetting>('selSingleLanguageSetting', param)
  },
  // 更新接口
  updLanguage(param = {}) {
    return client.post('updLanguage', param)
  },
  // IconPage页面数据
  IconPageData(param = {}) {
    return client.post('updIconTemplate', param)
  },
  // 上传图片的接口，返回一个链接
  createUploadUrl(param = {}) {
    return client.post('createUploadUrl', param)
  },
  // 获取多语言包
  getTemplateLanguage(param = {}) {
    return client.post('getTemplateLanguage', param)
  },
  // 页面拖拽排序功能
  sortTranslation(param = {}) {
    return client.post('sortTranslation', param)
  },
}
