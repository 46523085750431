import {
  Card,
  Layout,
  Box,
  Text,
  SkeletonBodyText,
  BlockStack,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

type PropsType = {
  i18nKeys?: string[]
}

export const SkeletonList = ({ i18nKeys }: PropsType) => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Layout.Section>
        <BlockStack gap={{ xs: '800', sm: '400' }}>
          {i18nKeys ? (
            i18nKeys?.map((i18nKey, index) => (
              <Card key={index}>
                <Box as="div" paddingBlockEnd="200">
                  <Text as="h2" variant="headingSm">
                    {t(i18nKey)}
                  </Text>
                </Box>
                <SkeletonBodyText lines={8} />
              </Card>
            ))
          ) : (
            <Card>
              <Box as="div" paddingBlockEnd="200"></Box>
              <SkeletonBodyText lines={20} />
            </Card>
          )}
        </BlockStack>
      </Layout.Section>
    </Layout>
  )
}
