export const polarisLocales: Record<string, string> = {
  // 参考: @shopify/polaris/locales
  en: `/shopify/locales/en.json`, // 原文
  de: `/shopify/locales/de.json`,
  es: `/shopify/locales/es.json`,
  fr: `/shopify/locales/fr.json`,
  it: `/shopify/locales/it.json`,
  ja: `/shopify/locales/ja.json`,
  ko: `/shopify/locales/ko.json`,
  ru: `/shopify/locales/ru.json`, // ✅ 机翻
  'zh-CN': `/shopify/locales/zh-CN.json`,
  'zh-TW': `/shopify/locales/zh-TW.json`,
  tr: `/shopify/locales/tr.json`,
  'pt-BR': `/shopify/locales/pt-BR.json`,
}

export const locales: {
  label: string
  value: string
  prefix?: React.ReactNode
}[] = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Español', value: 'es' },
  { label: 'Français', value: 'fr' },
  {
    label: 'Italiano',
    value: 'it',
  },
  { label: '日本語', value: 'ja' },
  {
    label: '한국어',
    value: 'ko',
  },
  { label: 'русский', value: 'ru' },
  { label: '繁體中文', value: 'zh-TW' },
  { label: '简体中文', value: 'zh-CN' },
  { label: 'Türkçe', value: 'tr' },
  { label: 'Português (Brasil)', value: 'pt-BR' },
]
