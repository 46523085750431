import { getSnapshot, subscribe } from '@/lib/auth/pubsub'
import { useSyncExternalStore } from 'react'

export function useAuth() {
  const isAuthenticated = useSyncExternalStore(subscribe, getSnapshot)

  return {
    isAuthenticated: isAuthenticated,
  }
}
