import { CouponProductRange } from '@/types/coupon'
import { notEmpty, useField } from '@shopify/react-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export function useRequiredField<T = any>(defaultValue: T, fieldName?: string) {
  const { t } = useTranslation()
  return useField<T>({
    value: defaultValue,
    validates: [
      notEmpty(
        t('Common.Error.required', {
          what: fieldName ?? t('Common.Field.this'),
        }),
      ),
    ],
    dirtyStateComparator: (a, b) => !_.isEqual(a, b),
  })
}

export function useNotFalsyField<T = any>(
  defaultValue: T,
  fieldName: string | null = null,
  enable = true,
) {
  const { t } = useTranslation()
  return useField<T>({
    value: defaultValue,
    validates: [
      (value: T) => {
        if (!enable) return undefined
        if (!value) {
          return t('Common.Error.required', {
            what: fieldName ?? t('Common.Field.this'),
          })
        }
        return undefined
      },
    ],
  })
}

export function useCollectionField(defaultValue: any[], productRange: number) {
  const { t } = useTranslation()
  return useField(
    {
      value: defaultValue,
      validates: [
        (value) => {
          if (productRange !== CouponProductRange.Collection) return
          if (!value || value.length === 0) {
            return t('Common.Error.apply')
          }
          return undefined
        },
      ],
    },
    [productRange],
  )
}
