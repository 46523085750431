import type { PlanType, UserInfo } from '@/atom/shop-info'
import { shopInfoAtom } from '@/atom/shop-info'
import { PLAN_NAMES } from '@/constants/keys'
import { getDefaultStore } from 'jotai'

export const init = (callback?: () => void) => {
  const script = document.createElement('script')
  script.async = true
  script.onload = () => {
    if (callback) {
      callback()
    }
  }
  script.src = `https://static.willdesk.com/c/willdesk.min.js?v=${new Date().getTime()}`
  document.body.appendChild(script)
}

export const willDeskHandler = () => {
  const globalStore = getDefaultStore()
  const store = globalStore.get(shopInfoAtom)
  const userInfo = store?.userInfo as UserInfo
  const planData = store?.plan as PlanType

  if (!userInfo || !planData) {
    return
  }
  const settings = {
    // 邮箱
    user_email: userInfo.email || 'no email',
    // 姓名
    user_name: userInfo.name || 'no name',
    // 商家id
    user_id: 'Loloyal_' + userInfo.shop_id || 'no userId',
    appUserId: 'Loloyal_' + userInfo.shop_id || 'no userId',
    // 评论名
    reviewName: userInfo.name || 'No owner',
    // 店铺域名
    shopUrl: userInfo.shop_name || 'no shopName',
    // app名称
    fromApp: 'Loloyal',
    // 评论星级
    review: `${userInfo.review_rating}-star` || '0-star',
    // app套餐
    plan: planData.plan.display_name || PLAN_NAMES.Free,
    // shopify店铺类型
    shopifyPlan: userInfo.plan_display_name || 'Unknown',
  }

  // 判断是否加载了Willdesk
  if (typeof window.Willdesk == 'object') {
    window.Willdesk.boot(settings)
  } else {
    let load = (window.WilldeskLoad = false)
    Object.defineProperty(window, 'WilldeskLoad', {
      get() {
        return load
      },
      set(newVal) {
        load = newVal
        if (newVal) {
          window.Willdesk.boot(settings)
        }
        return load
      },
    })
  }
}
