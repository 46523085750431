import { BlockStack, Box, Card, Text, Tooltip } from '@shopify/polaris'

interface TotalDataCardType {
  title: string
  titleDesc: string
  showNumber: string
}
export const TotalDataCard = ({
  title,
  titleDesc,
  showNumber,
}: TotalDataCardType) => {
  return (
    <Card roundedAbove="sm">
      <BlockStack gap="300">
        <Box>
          <Tooltip preferredPosition="below" content={titleDesc} hasUnderline>
            <Text variant="bodyMd" as="span" tone="subdued">
              {title}
            </Text>
          </Tooltip>
        </Box>
        <Text as="p" variant="bodyMd">
          <span className="text-[24px] font-bold">{showNumber}</span>
        </Text>
      </BlockStack>
    </Card>
  )
}
