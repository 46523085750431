import { router } from '@/index'

export const BackMap = {
  home: '/',
  program: '/program',
  'program/referrals': '/program/referrals',
  'program/pop-up': '/program/pop-up',
  customer: '/customer',
  'program/email': '/program/email',
  '../': '../',
  'program/limited-time-offers': '/program/limited-time-offers',
  'program/activity': '/program/activity',
  integration: '/integration',
  billing: '/billing',
  guidance: '/guidance',
}

export type BackMapKey = keyof typeof BackMap

export function navigateBack(fallback?: keyof typeof BackMap) {
  router.navigate({
    to: BackMap[fallback || 'home'] || BackMap.home,
    replace: true,
  })
}
