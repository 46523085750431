export type AppListItem = {
  id: number
  name: string
  short_name: string
  sort: number
  intro: string
  download_url: string
  tips_url: string
  icon_url: string
  banner_url?: string
  button_text: string
  connected?: number
}

export enum INTEGRATION_APP_IDS {
  TT = 1, // trustoo
  Klaviyo = 7, // Klaviyo
  JudgeMe = 2, // Judgeme
  WillDesk = 5, // WillDesk
  ShopifyFlow = 11, // Shopify Flow
}
