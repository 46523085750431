import { shopInfoAtom } from '@/atom/shop-info'
import { getDefaultStore } from 'jotai'

export function isGuidancePaths(href?: string) {
  const h = href ?? location.href
  return h.startsWith('/guidance') || h.startsWith('/platform/calendly')
}

export function shouldRedirectToGuidance(href?: string) {
  const defaultStore = getDefaultStore()
  const shopInfo = defaultStore.get(shopInfoAtom)

  if (!shopInfo) return false

  if (Number(shopInfo.completed_guide) === 0 && !isGuidancePaths(href)) {
    return true
  }
  return false
}
