import { userPlanInstanceAtom } from '@/atom/shop-info'
import { BENEFIT_KEYS } from '@/lib/plan/benefit-keys'
import { InlineStack, Link, Text } from '@shopify/polaris'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'

export const NotPaidPlan = ({ onClick }: { onClick: () => void }) => {
  const { t: billingT } = useTranslation('billing')
  const userPlanInstance = useAtomValue(userPlanInstanceAtom)
  const orderPermission = userPlanInstance.getPermission(
    BENEFIT_KEYS.order_limit,
    userPlanInstance.orderUsageCount,
  )
  return (
    <InlineStack gap={'200'}>
      <Text as="p" tone="subdued">
        {orderPermission ? (
          billingT('limitNotExceeded')
        ) : (
          <span className="text-[#FF2200]">{billingT('limitExceeded')}</span>
        )}
      </Text>
      <Link onClick={onClick}>
        {orderPermission
          ? billingT('clickToUpgrade')
          : billingT('upgradeToDisplay')}
      </Link>
    </InlineStack>
  )
}
