import type { RecommendDataType } from '@/api/navigation'
import getRecommendDataApi from '@/api/navigation'
import { PopoverCloseAction } from '@/components/common/popover/PopoverCloseAction'
import { ModuleCard } from '@/components/ui/card/ModuleCard'
import storageScheme from '@/lib/storage-scheme'
import {
  BlockStack,
  Box,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
} from '@shopify/polaris'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import type { Variants } from 'framer-motion'
import { AnimatePresence, motion } from 'framer-motion'
import { throttle, uniqueId } from 'lodash'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const sessionKey = 'CloseHomeRecommend'

export function SkeletonRecommend() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Text as="dd">
            <Box paddingBlockEnd="400">
              <SkeletonDisplayText size="small" />
            </Box>
            <SkeletonBodyText lines={4} />
          </Text>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
    }
  },
  center: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction > 0 ? '-100%' : '100%',
      opacity: 0,
    }
  },
}

function Item(props: { children: ReactNode; direction: number }) {
  return (
    <motion.div
      variants={variants}
      custom={props.direction}
      initial={'enter'}
      animate="center"
      exit={'exit'}
      className="absolute h-full w-full"
      transition={{
        x: { type: 'spring', duration: 0.6 },
        opacity: { duration: 0.2 },
      }}
    >
      {props.children}
    </motion.div>
  )
}

export const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

export function RecommendSwipe() {
  const { t: homeT } = useTranslation('home')
  const isMove = useRef(true)
  const pageSplit = 5
  const [[index, direction], setIndex] = useState([0, 0])
  const [show, setShow] = useState(
    !storageScheme.getItem(sessionKey, 'session'),
  )

  const { data, isLoading, error } = useQuery({
    queryKey: ['getRecommendData'],
    queryFn: async () => {
      return getRecommendDataApi
        .getRecommendData({
          appId: 4,
        })
        .then((res: { data: RecommendDataType[] }) => res.data)
    },
  })
  const splitArray = (array: any[] = [], chunkSize = 7) => {
    const result = []
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize)
      result.push(chunk)
    }
    return result
  }
  const items = splitArray(data, pageSplit).map((item, index) => {
    return (
      <div
        className="gap-6 md:flex md:flex-nowrap md:items-center md:justify-start"
        key={uniqueId()}
      >
        <div
          className="mb-6 h-[184px] w-auto cursor-pointer rounded-300 md:mb-0 md:w-[528px]"
          onClick={() => {
            window.open(item[0].jump_link)
          }}
        >
          <img
            className="h-[184px] w-auto rounded-300 md:w-[528px]"
            src={item[0].image}
            alt="recommend"
          />
        </div>
        <BlockStack gap={'400'}>
          {item.slice(1).map((ele, index) => (
            <div
              className="flex cursor-pointer items-center gap-3"
              key={ele.id}
              onClick={() => {
                window.open(ele.jump_link)
              }}
            >
              <div className="h-8 w-8">
                <img
                  className="h-8 w-8 rounded-100"
                  src={ele.image}
                  alt="recommend"
                />
              </div>
              <Text as="p">
                <span className="font-[500]">{ele.title}</span>
              </Text>
            </div>
          ))}
        </BlockStack>
      </div>
    )
  })
  const sliderIndex = wrap(0, items.length, index)

  const paginate = useCallback(
    (newDirection: number) => {
      setIndex([index + newDirection, newDirection])
    },
    [index],
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMove.current) {
        paginate(1)
      }
    }, 3000)
    return () => clearInterval(interval)
  }, [index, paginate])

  const handleDotClick = (newIndex: number) => {
    paginate(newIndex - sliderIndex)
  }

  const autoPlay = throttle(() => {
    isMove.current = true
  }, 200)

  const stopPlay = throttle(() => {
    isMove.current = false
  }, 200)

  const closeBanner = () => {
    storageScheme.setItem(sessionKey, 'true', 'session')
    setShow(false)
  }

  if (!data || isLoading || error) {
    return (
      <ModuleCard title={homeT('recommended')}>
        <SkeletonRecommend />
      </ModuleCard>
    )
  }
  return (
    <>
      {show && (
        <ModuleCard
          headerAction={<PopoverCloseAction onClick={closeBanner} />}
          title={homeT('recommended')}
        >
          <div onMouseLeave={autoPlay} onMouseEnter={stopPlay}>
            <div className="h-[380px] w-full md:h-[202px]">
              <AnimatePresence initial={false} custom={direction}>
                <Item key={index} direction={direction}>
                  {items[sliderIndex]}
                </Item>
              </AnimatePresence>
            </div>
            <div className="flex items-center justify-center gap-1.5 text-[#D1D1DB]">
              {items.map((_, index) => (
                <div
                  key={index}
                  className={classNames(
                    'h-1.5 w-1.5 cursor-pointer rounded-full bg-current transition-colors hover:bg-loloyal-primary',
                    {
                      'bg-loloyal-primary': sliderIndex === index,
                    },
                  )}
                  style={{
                    width: index === sliderIndex ? '48px' : '16px',
                  }}
                  onClick={() => handleDotClick(index)}
                ></div>
              ))}
            </div>
          </div>
        </ModuleCard>
      )}
    </>
  )
}

export default RecommendSwipe
