import type { COUPON_TYPES } from '@/constants/coupon'
import type { OnOff } from '@/types/val'

// 绑定付费套餐的优惠券类型
export type PaidCouponType =
  | 'free_shipping_coupon'
  | 'free_product_coupon'
  | 'shopify_pos'

export type CouponBaseType = {
  coupon_type: COUPON_TYPES
  status: OnOff

  amount: number
  points: number

  coupon_expire_status: number
  coupon_expire_time: number

  default_icon_id: OnOff
  icon_url: string
  is_upload_icon: OnOff

  required: OnOff
  required_mini_amount: number

  required_mini_points: OnOff
  mini_points: number
  required_max_points: OnOff
  max_points: number
}
export type NoLifetimeCoupon<T extends CouponBaseType> = Omit<
  T,
  'coupon_expire_status' | 'coupon_expire_time'
>
export type ShippingCombinesWith = {
  order_discounts: boolean
  product_discounts: boolean
}
export type CouponCombinesWith = ShippingCombinesWith & {
  shipping_discounts: boolean
}

export enum CouponProductRange {
  All = 1,
  Collection = 2,
  Product = 3,
}
export type CouponCollections = {
  product_range: CouponProductRange
  collection_id: number[]
  select_collection: number[]
}
export type FixedAmountCoupon = CouponBaseType &
  CouponCollections & {
    coupon_type: COUPON_TYPES.Fixed
    combines_with: CouponCombinesWith
  }
