import client from '@/lib/request'
import type { PricingPlans } from './types/billing'

export default {
  // billing页面初始化
  selBillingInfo(param = {}) {
    return client.post('selBillingInfo', param)
  },
  // 订阅
  addPlan(param = {}) {
    return client.post('addPlan', param)
  },
  // 取消订阅
  delPlan(param = {}) {
    return client.post('delPlan', param)
  },
  // 查询订阅是否成功
  checkPlanStatus(param = {}) {
    return client.post('checkPlanStatus', param)
  },
  // 查询降级状态
  checkDowngradeStatus(param = {}) {
    return client.post('checkDowngradeStatus', param)
  },
  // 售卖的套餐内容
  getSubscribePlan(param = {}) {
    return client.post<PricingPlans>('userPlan/plan', param)
  },
  // 获取全部权益信息
  getPlanBenefits(param = {}) {
    return client.post('userPlan/planBenefit', param)
  },
  // 触发邮件发送
  selectPlan(param = {}) {
    return client.post('selectPlan', param)
  },
}
