import client from '@/lib/request'
import type { LabelSettingParam, LabelPayload } from './types/tools'

export default {
  getLabelSetting(param = {}) {
    return client.post<LabelSettingParam>('/getLabelSetting', param)
  },
  setLabelSetting(param: LabelPayload) {
    return client.post('/setLabelSetting', param)
  },
  changeLabelState(param: { state: number }) {
    return client.post('/changeLabelState', param)
  },
}
