import { BlockStack, Box, Button, Card, Divider, Text } from '@shopify/polaris'
import type { PropsWithChildren } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export function ErrorBoundaryClient(props: PropsWithChildren<{}>) {
  return (
    <ErrorBoundary FallbackComponent={fallbackRender}>
      {props.children}
    </ErrorBoundary>
  )
}
function fallbackRender({ error, resetErrorBoundary }: any) {
  return (
    <RouterErrorComponent
      error={error}
      reset={resetErrorBoundary}
    ></RouterErrorComponent>
  )
}

export function RouterErrorComponent({ error, reset }: any) {
  return (
    <div className="container mx-auto max-w-[48rem]">
      <Card>
        <Box paddingBlockEnd={'400'}>
          <Text variant="headingMd" as="h3">
            Unexpected error
          </Text>
        </Box>
        <Divider></Divider>
        <Box paddingBlockStart={'400'}>
          <BlockStack gap={'300'}>
            <Text as="p" variant="bodyLg">
              An unexpected error occurred. We apologize for the inconvenience.
              Sorry for the trouble. 😥
            </Text>
            <Text as="p" variant="bodyLg">
              Click the button below to retry or refresh the page. If the error
              persists, please contact us for help.
            </Text>
            <Box>
              <Button variant="primary" onClick={() => reset()}>
                retry
              </Button>
            </Box>
            <pre className="overflow-auto text-wrap  text-gray-500">
              Error Message: {error.message}
            </pre>
          </BlockStack>
        </Box>
      </Card>
    </div>
  )
}
