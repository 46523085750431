import { t } from 'i18next'

function checkMini(min: number, equal: boolean) {
  return (value: number) => {
    if (equal) {
      if (value < min) {
        return t('Common.Input.valueThan', { value: min })
      }
    } else {
      if (value <= min) {
        return t('Common.Input.valueEqualOrThan', { value: min })
      }
    }
  }
}
function checkMax(max: number, equal: boolean) {
  return (value: number) => {
    if (equal) {
      if (value > max) {
        return t('Common.Input.valueLess', { value: max })
      }
    } else {
      if (value >= max) {
        return t('Common.Input.valueEqualOrLess', { value: max })
      }
    }
  }
}

export default {
  checkMini,
  checkMax,
}
