import client from '../lib/request'

export default {
  authUser(param: Record<string, any> = {}) {
    // param.no_auth_header = 1
    return client.post<{
      status: number
      finished_steps: string
      completed_guide: number
      token: string
      url: string
    }>('admin/auth', param)
  },
  login(param = {}) {
    return client.post('admin/login', param)
  },
  fixFunction(param = {}) {
    return client.post('fixFunction', param)
  },
}
