import earnPoints from '@/api/earnPoints'
import {
  IntegratedApp,
  ReviewPageType,
} from '@/components/modules/rule-components/type/review'
import { SkeletonInlineGrid } from '@/components/ui/skeleton'
import i18n from '@/i18n'
import type { FormContextType } from '@/routes/rule/review.lazy'
import { createFileRoute } from '@tanstack/react-router'
import { createContext } from 'react'

export const Route = createFileRoute('/rule/review')({
  validateSearch: (params) =>
    params as {
      isEdit?: boolean
      ruleId: string
    },
  loaderDeps: ({ search: { ruleId, isEdit } }) => ({
    isEdit,
    ruleId,
  }),
  async loader({ deps: { ruleId, isEdit } }) {
    const [data1, data2] = await Promise.all([
      earnPoints
        .getVstarStatus()
        .then((res: { data: IntegratedApp[] }) => res.data),
      earnPoints
        .getRuleData({
          rule_id: Number(ruleId),
        })
        .then((res: { data: ReviewPageType }) => res.data),
      i18n.loadNamespaces('program/common'),
      i18n.loadNamespaces('program/rule'),
    ])
    return [data1, data2]
  },
  pendingComponent: SkeletonInlineGrid,
  errorComponent: SkeletonInlineGrid,
})

export const FormContext = createContext<FormContextType>({} as FormContextType) // <--- 留意这个类型
