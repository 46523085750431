import { ErrorBoundaryClient, RouterErrorComponent } from '@/ErrorBoundary'
import { Welcome } from '@/components/ui/welcome'
import i18n from '@/i18n'
import { polarisLocales } from '@/i18n/languages'
import { AppProvider } from '@shopify/polaris'
import {
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'
import { Link as ReactRouterLink, RouterProvider } from '@tanstack/react-router'
import { Suspense, useSyncExternalStore } from 'react'
import { queryClient, router } from '.'
import { NotFound } from '@/components/common/not-found/NotFound'

export function App() {
  return (
    <ErrorBoundaryClient>
      <Suspense fallback={<Welcome />}>
        <QueryClientProvider client={queryClient}>
          <PolarisProvider>
            <RouterProvider
              defaultNotFoundComponent={() => <NotFound />}
              defaultErrorComponent={RouterErrorComponent}
              router={router}
            />
          </PolarisProvider>
        </QueryClientProvider>
      </Suspense>
    </ErrorBoundaryClient>
  )
}

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/
function Link({ children, url = '', external, ref, ...rest }: any) {
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = '_blank'
    rest.rel = 'noopener noreferrer'
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    )
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  )
}
function PolarisProvider(props: { children?: React.ReactNode }) {
  const globalLanguage = useSyncExternalStore(
    (callback) => {
      i18n.on('languageChanged', callback)
      return () => {
        i18n.off('languageChanged', callback)
      }
    },
    () => i18n.language,
  )
  const { data: i18nData, isLoading } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['@shopify/polaris/locales/', globalLanguage],
    queryFn: async () => {
      const jsonURL =
        polarisLocales[globalLanguage] || '/shopify/locales/en.json'
      return (await fetch(jsonURL).then((res) => res.json())) as any
    },
  })

  if (isLoading) return <Welcome />
  return (
    <AppProvider linkComponent={Link} i18n={i18nData}>
      {props.children}
    </AppProvider>
  )
}
