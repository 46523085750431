import rewards from '@/api/rewards'
import { COUPON_TYPES } from '@/constants/coupon'
import { use0ToBillion, use1To999x } from '@/hooks/common/use-number-field'
import { navigateBack } from '@/lib/navigate-back'
import ShopifyBridge from '@/lib/shopify-bridge'
import { useField, useForm } from '@shopify/react-form'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'

export const DEFAULT_POS_AMOUNT_COUPON = {
  coupon_type: COUPON_TYPES.PosAmount,
  product_range: 1,
  amount: 10,
  points: 100,
  required: 1,
  required_mini_amount: 0.01,
  collection_id: [],
  is_maximum: 1,
  product_rid: 0,
  status: 1,
}

export function usePosCouponForm(
  id: string | number | undefined,
  amountCoupon: typeof DEFAULT_POS_AMOUNT_COUPON,
) {
  const isEdit = !!id
  const isPercentage = amountCoupon.coupon_type === COUPON_TYPES.PosPercentage

  const { t } = useTranslation()
  const amount = use0ToBillion(
    isPercentage ? amountCoupon.amount * 100 : amountCoupon.amount,
  )
  const points = use1To999x(amountCoupon.points)
  const status = useField(amountCoupon.status)

  const form = useForm({
    fields: {
      amount,
      points,
      status,
    },
    makeCleanAfterSubmit: true,
    onSubmit: async (values) => {
      try {
        if (isEdit) {
          const submitData = { ...amountCoupon, ...values }
          if (isPercentage) {
            submitData.amount = submitData.amount / 100
          }
          await rewards.rewardsUpdateCoupon({
            id: Number(id),
            ...submitData,
          })

          ShopifyBridge.toast.show(t('Common.Success.save'))
        } else {
          const submitData = { ...amountCoupon, ...values }
          if (isPercentage) {
            submitData.amount = submitData.amount / 100
          }
          await rewards.createCoupon(submitData)

          ShopifyBridge.toast.show(t('Common.Success.save'))
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              navigateBack('program')
            })
          })
        }
        return {
          status: 'success',
        }
      } catch (e: any) {
        return {
          status: 'fail',
          errors: [
            {
              message: e.message,
            },
          ],
        }
      }
    },
  })

  // useLeaveBlocker(form.dirty)
  return form
}

export const PosAmountFormContext = createContext<
  ReturnType<typeof usePosCouponForm>
>(null as any)
