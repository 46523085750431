export const isEmbedded = () => {
  if (!window.shopify) return false
  return window.shopify.environment.embedded
}

export const toast = {
  show:
    isEmbedded() && shopify.toast
      ? shopify.toast.show
      : (((msg: string, ...args: any) => {
          alert(msg)
        }) as typeof shopify.toast.show),
}

const ShopifyBridge = {
  toast,
}
export default ShopifyBridge

// polyfill for shopify
if (!window.shopify) {
  window.shopify = {
    ready: Promise.resolve(),
    toast: {
      show(msg: string, _?: any) {
        alert(msg)
        return 'toast-id-1'
      },
      hide(id: string) {
        console.log('hide', id)
      },
    },
    environment: {
      embedded: false,
    },
    saveBar: {
      async leaveConfirmation() {
        return true
      },
      async hide() {
        return true
      },
    },
    async resourcePicker() {
      return { data: [] }
    },
  } as any
}
