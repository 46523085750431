import { Icon, Popover } from '@shopify/polaris'
import { MenuHorizontalIcon } from '@shopify/polaris-icons'
import { useCallback, useState } from 'react'

export const PopoverAction = ({
  activator,
  children,
}: {
  activator?: React.ReactElement
  children?: React.ReactNode
}) => {
  const [popoverActive, setPopoverActive] = useState(false)

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )

  const activatorContent = activator ?? (
    <div
      className="cursor-pointer rounded-[--p-border-radius-100] p-1 hover:bg-[--p-color-bg-surface-hover]"
      onClick={togglePopoverActive}
    >
      <Icon source={MenuHorizontalIcon} tone="base" />
    </div>
  )

  return (
    <Popover
      sectioned
      active={popoverActive}
      activator={activatorContent}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
    >
      {children}
    </Popover>
  )
}
