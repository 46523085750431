import earnPointsApi from '@/api/earnPoints'
import { SkeletonInlineGrid } from '@/components/ui/skeleton'
import i18n from '@/i18n'
import { getRuleDefault } from './getRuleDefault'

export const routeConfig = () => {
  return {
    validateSearch: (params: any) =>
      params as {
        isEdit?: boolean
        ruleId: string
      },
    loaderDeps: ({ search: { ruleId, isEdit } }: any) => ({
      isEdit,
      ruleId,
    }),
    async loader({ deps: { ruleId, isEdit } }: any) {
      const [data] = await Promise.all([
        earnPointsApi
          .getRuleData({
            rule_id: Number(ruleId),
          })
          .then((res) => {
            if (isEdit) {
              return res.data
            } else {
              const defaultValue = getRuleDefault(res.data.rule_id)
              return {
                ...defaultValue,
                id: res.data.id,
                rule_id: res.data.rule_id,
                languages: res.data.languages,
              }
            }
          }),
        i18n.loadNamespaces('program/common'),
        i18n.loadNamespaces('program/rule'),
      ])
      return data
    },
    pendingComponent: SkeletonInlineGrid,
    errorComponent: SkeletonInlineGrid,
  }
}
