import { atom } from 'jotai'

export type Filter = {
  condition: string
  field: string
  value: string | number | boolean
}

export const searchCustomerPageAtom = atom<number>(1)
export const filtersAtom = atom<Filter[]>([])
export const searchCustomerKeywordAtom = atom<string>('')
export const searchCustomerSortAtom = atom<string>('newest_first')
export const keepHistoryAtom = atom(false)
