import integration$ from '@/api/integration'
import { DetailPageSkeleton } from '@/components/ui/skeleton'
import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/integration/trustoo')({
  loader: async () => {
    const [data] = await Promise.all([
      integration$.getTTConnectStatus().then((res) => res.data),
      i18n.loadNamespaces('integration'),
    ])
    return data
  },
  pendingMs: 200,
  pendingComponent: () => <DetailPageSkeleton />,
  gcTime: 0,
})
