import { BlockStack, Button, Page, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Page>
      <div className="flex h-[500px] items-center justify-center">
        <BlockStack gap={'400'} align="center" inlineAlign="center">
          <BlockStack align="center" inlineAlign="center">
            <div className="h-[219px] w-[292px]">
              <img
                className="h-[219px] w-[292px]"
                src="/modules/not-found/404.webp"
                alt="not found"
              />
            </div>
            <Text as="span" variant="bodyMd">
              <span className="text-[16px] font-[650]">{t('404.title')}</span>
            </Text>
            <Text as="span" variant="bodyMd" tone="subdued">
              <span className="text-[13px] font-[450]">{t('404.desc')}</span>
            </Text>
          </BlockStack>
          <Button variant="primary" url="/">
            {t('404.action')}
          </Button>
        </BlockStack>
      </div>
    </Page>
  )
}
