import { REFERRAL_REWARD_TYPES } from '@/constants/coupon'
import { composeReferralCouponRouteTemplate } from '@/lib/coupon/gen-code'
import { DEFAULT_REFERRAL_AMOUNT_COUPON } from '@/providers/coupon/ReferralCouponProvider'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/referral-coupon/percentage')(
  composeReferralCouponRouteTemplate({
    ...DEFAULT_REFERRAL_AMOUNT_COUPON,
    coupon_type: REFERRAL_REWARD_TYPES.Percentage,
    amount: 0.1,
  }),
)
