import { alphabetMatch } from '@/lib/presets'
import type { FC } from 'react'

interface AvatarProps {
  url?: string
  name?: string
  alt?: string
}

export const Avatar: FC<AvatarProps> = ({ url, alt, name }) => {
  return (
    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200">
      <img
        src={url ?? `/avatar/${alphabetMatch(name ?? '')}.svg`}
        alt={alt ?? 'avatar'}
        className="w-10 h-10 rounded-full object-cover"
      />
    </div>
  )
}
