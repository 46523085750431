import { useRouterState } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import LoadingBar from 'react-top-loading-bar'

export function PageProgress() {
  const isLoading = useRouterState({
    select: (state) => state.isLoading,
  })
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    let timer: any = null
    if (isLoading) {
      setProgress(16)
    } else {
      setProgress(100)
    }
    return () => {
      clearInterval(timer)
    }
  }, [isLoading])

  return (
    <LoadingBar
      color="#666cfe"
      progress={progress}
      waitingTime={300}
      height={2}
      onLoaderFinished={() => {
        setProgress(0)
      }}
    />
  )
}
