import popup from '@/api/popup'
import { POPUP_TYPE } from '@/constants/popup'
import i18n from '@/i18n'
import { createFileRoute, defer } from '@tanstack/react-router'

export const Route = createFileRoute('/popup/increase-points')({
  loader: async () => {
    await i18n.loadNamespaces('program/popup')
    const dataPromise = popup
      .getPopupsInfo({
        popups_type: POPUP_TYPE.Points,
      })
      .then((resp) => resp.data)
    return {
      templateData: defer(dataPromise),
    }
  },
  gcTime: 0,
})
