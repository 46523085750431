import client from '@/lib/request'
import type { CouponListResponse } from './types/coupon'

export default {
  // 查询优惠券
  queryCoupon(param: { page_num: number; page_size: number }) {
    return client.post<CouponListResponse>('queryCoupon', param)
  },
  // 修改优惠券状态
  updCouponStatus(param = {}) {
    return client.post('updCouponStatus', param)
  },
  // 优惠券排序
  sortCoupon(param = {}) {
    return client.post('sortCoupon', param)
  },
  // 删除优惠券
  delCoupon(param = {}) {
    return client.post('delCoupon', param)
  },
  // 查询产品系列信息
  queryCollection(param = {}) {
    return client.post<
      Array<{
        collection_rid: number
        title: string
      }>
    >('rewardsSelectCollectionsTitle', param)
  },

  // 新增优惠券
  createCoupon(param = {}) {
    return client.post('createCoupon', param)
  },

  // Coupon优惠券更新
  rewardsUpdateCoupon(param = {}) {
    return client.post('rewardsUpdateCoupon', param)
  },

  // 根据优惠券id查询对应的优惠券信息
  queryCouponById(params = {}) {
    return client.post('queryCouponById', params)
  },

  // 根据优惠券ID查询对应collection
  couponCollection(param = {}) {
    return client.post('couponCollection', param)
  },

  // 查询商品
  productFind(param = {}) {
    return client.post('productFind', param)
  },
}
