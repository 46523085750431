import { Avatar } from '@/components/ui/avatar'
import type { CardProps } from '@shopify/polaris'
import {
  Bleed,
  BlockStack,
  Card,
  Divider,
  InlineStack,
  Text,
} from '@shopify/polaris'
import React from 'react'
import './ModuleCard.css'

type PropsType = {
  title: string
  avatar?: string
  hideDivider?: boolean
  headerAction?: React.ReactNode
  headerSection?: React.ReactNode
  children?: React.ReactNode
  background?: CardProps['background']
}

export const ModuleCard = (props: PropsType) => {
  const headerMarkup = (
    <BlockStack gap="100">
      <Text variant="headingMd" as="h6">
        {props.title}
      </Text>
      {props.headerSection}
    </BlockStack>
  )

  return (
    <div className="loloyal-module-card">
      <Card background={props.background}>
        <BlockStack gap="400">
          <Bleed marginBlock="100">
            <InlineStack blockAlign="center" gap="300">
              {props.avatar ? (
                <Avatar url={props.avatar} alt={props.title} />
              ) : null}
              {headerMarkup}
              {props.headerAction ? (
                <div className="ml-auto">{props.headerAction}</div>
              ) : null}
            </InlineStack>
          </Bleed>
          {props.hideDivider ? null : (
            <Bleed marginInline="400">
              <Divider borderColor="border" />
            </Bleed>
          )}
          {props.children}
        </BlockStack>
      </Card>
    </div>
  )
}
