import React, { useState, useEffect, useRef } from 'react'
import './CountDown.css'

type CountDownProps = {
  time?: number
  end?: number
  isMiniSecond?: boolean
  children: (timeObj: {
    d: string
    h: string
    m: string
    s: string
  }) => React.ReactNode
}

const CountDown: React.FC<CountDownProps> = ({
  time = 0,
  end = 0,
  isMiniSecond = false,
  children,
}) => {
  const [days, setDays] = useState<string | number>('0')
  const [hours, setHours] = useState<string | number>('00')
  const [mins, setMins] = useState<string | number>('00')
  const [seconds, setSeconds] = useState<string | number>('00')
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const curTime = useRef(0)

  const durationFormatter = (time: number) => {
    if (!time) return { ss: 0 }

    let t = time
    const ss = t % 60
    t = (t - ss) / 60

    if (t < 1) return { ss }

    const mm = t % 60
    t = (t - mm) / 60

    if (t < 1) return { mm, ss }

    const hh = t % 24
    t = (t - hh) / 24

    if (t < 1) return { hh, mm, ss }

    const dd = t

    return { dd, hh, mm, ss }
  }

  const getDuration = () => {
    if (end) {
      let endValue = String(end).length >= 13 ? +end : +end * 1000
      endValue -= Date.now()
      return endValue
    }

    const timeInSecs = isMiniSecond
      ? Math.round(+time / 1000)
      : Math.round(+time - new Date().getTime() / 1000)

    return timeInSecs
  }

  useEffect(() => {
    countDown()
  }, [])

  useEffect(() => {
    countDown()
  }, [end])

  const countDown = () => {
    curTime.current = Date.now()
    getTime(getDuration())
  }

  const getTime = (time: number) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    if (time < 0) {
      return
    }

    const { dd, hh, mm, ss } = durationFormatter(time)

    setDays(dd || '0')
    setHours(hh || '00')
    setMins(mm || '00')
    setSeconds(ss || '00')

    timerRef.current = setTimeout(() => {
      const now = Date.now()
      const diffTime = Math.floor((now - curTime.current) / 1000)
      const step = diffTime > 1 ? diffTime : 1 // 页面退到后台的时候不会计时，对比时间差，大于1s的重置倒计时
      curTime.current = now
      getTime(time - step)
    }, 1000)
  }

  return (
    <div className="base-count-down no-rtl">
      <div className="content">
        {children({
          d: `00${days}`.slice(-2),
          h: `00${hours}`.slice(-2),
          m: `00${mins}`.slice(-2),
          s: `00${seconds}`.slice(-2),
        })}
      </div>
    </div>
  )
}

export default CountDown
