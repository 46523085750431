import { isEmbedded } from '@/lib/shopify-bridge'
import { NavMenu } from '@shopify/app-bridge-react'
import { useLinkProps } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

export const Sidebar = () => {
  const { t } = useTranslation()
  const Links = (
    <>
      <SidebarLink url="/program">{t('Common.Menu.Program')}</SidebarLink>
      <SidebarLink url="/brand-style">
        {t('Common.Menu.Brand Style')}
      </SidebarLink>
      <SidebarLink url="/integration">
        {t('Common.Menu.Integration')}
      </SidebarLink>
      <SidebarLink url="/customers">{t('Common.Menu.Customers')}</SidebarLink>
      <SidebarLink url="/analytics">{t('Common.Menu.Analytics')}</SidebarLink>
      <SidebarLink url="/billing">{t('Common.Menu.Billing')}</SidebarLink>
      <SidebarLink url="/settings">{t('Common.Menu.Settings')}</SidebarLink>
    </>
  )

  if (!isEmbedded()) {
    return (
      <div
        className={
          'sticky top-8 z-3 mx-auto mt-8 flex w-fit flex-wrap items-center justify-center gap-4 rounded-400 bg-white px-6 py-4 text-center shadow-lg'
        }
      >
        <SidebarLink url="/">Home</SidebarLink>
        {Links}
      </div>
    )
  }

  return <NavMenu>{Links}</NavMenu>
}

function SidebarLink({
  url,
  children,
}: {
  url: string
  children: React.ReactNode
}) {
  // 过滤掉 aria-current 属性，因为 Shopify App Bridge 会报错
  const { onClick, href } = useLinkProps({
    to: url,
  })
  return (
    <a onClick={onClick} href={href}>
      {children}
    </a>
  )
}
