import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/email')({
  loader: () =>
    Promise.all([
      i18n.loadNamespaces('program/common'),
      i18n.loadNamespaces('program/email'),
    ]),
  pendingMs: 200,
})
