import client from '@/lib/request'

export default {
  // 查询earn points的初始化数据
  getRuleData(param = {}) {
    return client.post('getRuleData', param)
  },
  // 保存接口
  updPointRulesSetting(param = {}) {
    return client.post('updPointRulesSetting', param)
  },
  // 查询vstar与loloyal连接状态
  getVstarStatus(param = {}) {
    return client.post('vstar/connectInfo', param)
  },
}
