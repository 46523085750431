import client from '@/lib/request'

export default {
  // 查询program三个模块的开关
  selectProgramStatus(param = {}) {
    return client.post('selectProgramStatus', param)
  },

  // 积分规则查询
  selectAllSetting(param = {}) {
    return client.post('selectAllSetting', param)
  },

  // 查询分类信息
  selectProductCollection(param = {}) {
    return client.post('selectProductCollection', param)
  },

  // 查询名
  pointNameSelect(param = {}) {
    return client.post('pointNameSelect', param)
  },

  // Login状态码的更替
  updateLoginStatus(param = {}) {
    return client.post('updateLoginStatus', param)
  },

  updateOrderStatus(param = {}) {
    return client.post('updateOrderStatus', param)
  },

  // 各渠道状态码的更替
  updateChannelStatus(param = {}) {
    return client.post('updateChannelStatus', param)
  },

  // 各渠道转发链接的设置
  referralChannelSetting(param = {}) {
    return client.post('referralChannelSetting', param)
  },

  // 转发渠道初始化数据查询
  selectChannelInfo(param = {}) {
    return client.post('selectChannelInfo', param)
  },

  // Coupon状态码的更替
  updateCouponStatus(param = {}) {
    return client.post('updateCouponStatus', param)
  },

  // points全局状态码的更替
  updatePointsStatus(param = {}) {
    return client.post('updatePointsStatus', param)
  },

  // Coupon优惠券删除
  deleteCoupon(param = {}) {
    return client.post('deleteCoupon', param)
  },

  // Coupon优惠券更新
  updateCoupon(param = {}) {
    return client.post('updateCoupon', param)
  },

  // 修改登录积分
  updateSettingLogin(param = {}) {
    return client.post('updateSettingLogin', param)
  },

  // 修改生日和订阅积分
  updateSettingEarnChannel(param = {}) {
    return client.post('updateSettingEarnChannel', param)
  },

  // 修改生日和订阅积分状态
  updateEarnChannelState(param = {}) {
    return client.post('updateEarnChannelState', param)
  },

  // 修改订单积分
  updateSettingOrder(param = {}) {
    return client.post('updateSettingOrder', param)
  },

  // 新增优惠券
  addNewCoupon(param = {}) {
    return client.post('addNewCoupon', param)
  },

  // 根据优惠券ID查询对应collection
  selCollectionByCouponId(param = {}) {
    return client.post('selectCollectionsTitle', param)
  },

  // 更新显示主题弹窗的状态
  updThemeWindowStatus(param = {}) {
    return client.post('updThemeWindowStatus', param)
  },

  // 更新主题模版
  updateThemeTemplate(param = {}) {
    return client.post('updateThemeTemplate', param)
  },

  // 更新主题模版
  selLanguage(param = {}) {
    return client.post('script/translation', param)
  },

  // 查询C端状态
  getClientStatus(param = {}) {
    return client.post('getClientStatus', param)
  },

  // 添加和编辑集成规则
  updateSettingReview(param = {}) {
    return client.post('updateSettingReview', param)
  },
  // 删除集成规则
  deleteSettingReview(param = {}) {
    return client.post('deleteSettingReview', param)
  },
}
