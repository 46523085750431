import popup$ from '@/api/popup'
import type { PopupDesignModel } from '@/components/modules/popup/design/DataProvider'
import i18n from '@/i18n'
import { createFileRoute, defer } from '@tanstack/react-router'

export const Route = createFileRoute('/popup/design')({
  loader: async () => {
    await i18n.loadNamespaces('program/popup')
    const designDataPromise = popup$
      .popupsDesign()
      .then((res) => res.data as PopupDesignModel)
    return {
      designData: defer(designDataPromise),
    }
  },
  gcTime: 0,
})
