import client from '@/lib/request'
import type { HomeDataType } from './types/dashboard'

export default {
  getAllData(param = {}) {
    return client.post('getAllData', param)
  },
  // 获取首页数据
  getHomeData(param = {}) {
    return client.post<HomeDataType>('getHomeData', param)
  },
  // 获取积分变动和vip变动流水
  activityHistory(param = {}) {
    return client.post('activityHistory', param)
  },
  // 点击首页彩蛋
  activeEasterEggs(param = {}) {
    return client.post('activeEasterEggs', param)
  },
  // 首页右侧评论状态
  updFeedbackStatus(param = {}) {
    return client.post('updFeedbackStatus', param)
  },
  // 更改用户新人状态
  updFirstEntry(param = {}) {
    return client.post('updFirstEntry', param)
  },
}
