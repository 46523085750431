import BillingApi from '@/api/billing'
import storageScheme from '../storage-scheme'
import { initGlobalPlan } from './index'

let planTimer: NodeJS.Timeout | null,
  timerList: NodeJS.Timeout[] = [],
  pollEventFlag = 0,
  pollingCount = 0
const chargeId = storageScheme.getItem('add-plan-charge-id')

const getPlanStatus = () => {
  BillingApi.checkPlanStatus({ charge_id: Number(chargeId) }).then(
    async (res) => {
      pollEventFlag = res.data.payment_status
      if (res.data.payment_status !== 0) {
        // 更新全局数据
        BillingApi.getPlanBenefits().then((res) => {
          initGlobalPlan(res.data)
        })
      }
    },
  )
}
const clearTimeOutFun = () => {
  timerList.forEach((item) => {
    clearInterval(item)
    planTimer = null
  })
  timerList = []
}
const pollingPlan = () => {
  if (chargeId) {
    planTimer = setInterval(() => {
      pollingCount++
      getPlanStatus()
      if (pollEventFlag !== 0 || pollingCount > 10) {
        clearTimeOutFun()
      }
    }, 2000)
    timerList.push(planTimer)
  }
}

export { pollingPlan }
