import client from '@/lib/request'
import type {
  EmailInitResponse,
  EmailSetResponse,
  GlobalEmailResponse,
  TemplateEmailResponse,
  UpdateGlobalEmailPayload,
  updateTemplateEmailPayload,
} from './types/email'

export default {
  // 更改顾客参与忠诚计划条件
  getEmailSetting() {
    return client.post<EmailSetResponse>('getEmailSetting')
  },
  // 更改邮件收件地址和发送者信息
  updEmailSetting(param: { sender_name: string; reply_to: string }) {
    return client.post('updEmailSetting', param)
  },
  // email notification 页面数据查询
  emailInit() {
    return client.post<EmailInitResponse>('emailInit')
  },
  // 邮件通知总状态开启与关闭
  updEmailStatus(param: { email_status: number }) {
    return client.post('updEmailStatus', param)
  },
  // 邮件通知的模板开关
  updEmailTemplateStatus(param: { id: number; status: number }) {
    return client.post('updEmailTemplateStatus', param)
  },
  // 邮件通知的多语言开关
  updEmailLanguageStatus(param: { language_status: number }) {
    return client.post('updEmailLanguageStatus', param)
  },
  // 获取全局邮件模板设置信息
  getEmailGlobalSetting() {
    return client.post<GlobalEmailResponse>('getEmailGlobalSetting')
  },
  // 更改全局邮件模板公共设置信息
  updEmailGlobalSetting(param: UpdateGlobalEmailPayload) {
    return client.post('updEmailGlobalSetting', param)
  },
  // 获取单独模板信息
  getEmailTemplate(param: { id: number }) {
    return client.post<TemplateEmailResponse>('getEmailTemplate', param)
  },
  // 更改单独模板信息
  updEmailTemplate(param: updateTemplateEmailPayload) {
    return client.post('updEmailTemplate', param)
  },
  // 发送测试邮件
  sendTestEmail(param: any) {
    return client.post('sendTestEmail', param)
  },
}
