/**
 * 套餐等级 Free < Free easter egg < Free unlocked < Starter < Growth < Plus
 */
import type {
  DowngradePlan,
  Plan,
  PlanType,
  PlanUsageAmount,
} from '@/atom/shop-info'
import { PLAN_NAMES } from '@/constants/keys'
import i18next, { t } from 'i18next'
import { toLower } from 'lodash'
import type { Benefit } from './Class'
import { createBenefitInstances } from './Instance'
import type { BENEFIT_KEYS } from './benefit-keys'

await Promise.all([i18next.loadNamespaces('translation')])

export class UserPlan {
  plan: Plan
  planUsageAmount: PlanUsageAmount
  downgradePlan: DowngradePlan
  userBenefits: Benefit[]
  constructor(planData: PlanType) {
    this.plan = planData.plan
    this.planUsageAmount = planData.plan_usage_amount
    this.downgradePlan = planData.downgrade_plan
    this.userBenefits = createBenefitInstances(planData.user_benefit)
  }
  #getBenefit(benefitKey: BENEFIT_KEYS) {
    return this.userBenefits.find(
      (benefit) => benefit.benefitKey === benefitKey,
    )
  }
  getPermission(benefitKey: BENEFIT_KEYS, orderCount?: number) {
    return this.#getBenefit(benefitKey)?.hasPermission(orderCount)
  }
  getBenefitApply(benefitKey: BENEFIT_KEYS) {
    return t(
      `Common.Plan.${toLower(this.#getBenefit(benefitKey)?.applyToPlan())}`,
    )
  }
  getBenefitValue(benefitKey: BENEFIT_KEYS) {
    return this.#getBenefit(benefitKey)?.value
  }
  get isPaidPlan() {
    return this.plan.is_free === 0
  }
  get currency() {
    // 货币
    return this.plan.currency
  }
  get hasEarlyBird() {
    // 是否有早鸟优惠
    return this.plan.is_early_bird
  }
  get subscribeStartTime() {
    // 订阅开始时间
    return this.plan.sub_start_time
  }
  get subscribeExpireTime() {
    // 订阅到期时间
    return this.plan.sub_expire_time
  }
  get subscribeAmount() {
    // 订阅费用
    return this.plan.sub_amount
  }
  get displayName() {
    // 套餐名字【Free包含 Free | Free easter egg 】
    return this.plan.display_name
  }
  get downgradeDisplayName() {
    // 降级目标套餐
    return this.downgradePlan.display_name
  }
  get downgradeOrderLimit() {
    // 降级目标套餐订单限制
    return this.downgradePlan.order_limit
  }
  get interval() {
    // 订阅周期
    return this.plan.interval
  }
  get trial() {
    // 当前套餐试用天数
    return this.plan.trial
  }
  get orderUsageCount() {
    // 已使用的订单数量
    return this.planUsageAmount.order_limit
  }
  get orderExpireTime() {
    // 订单超出后的缓冲时间（倒计时）
    return this.planUsageAmount.order_expire_time
  }
  get isFree() {
    // free 套餐 (彩蛋和 free 套餐同名)
    return this.displayName === PLAN_NAMES.Free && !this.isFreeEasterEgg
  }
  get isFreeEasterEgg() {
    // free easter egg 套餐
    return this.plan.is_easter_eggs
  }
  get isFreeUnlocked() {
    // free unlocked 套餐
    return this.displayName === PLAN_NAMES.FreeUnlocked
  }
  get isStarter() {
    // Starter 套餐
    return this.displayName === PLAN_NAMES.Starter
  }
  get isGrowth() {
    // Growth 套餐
    return this.displayName === PLAN_NAMES.Growth
  }
  get isPlus() {
    // Plus 套餐
    return this.displayName === PLAN_NAMES.Plus
  }
  get isLTFreeEasterEgg() {
    // 低于 Free easter egg 套餐(Free)
    return (
      !this.isFreeEasterEgg &&
      ![
        PLAN_NAMES.FreeUnlocked,
        PLAN_NAMES.Starter,
        PLAN_NAMES.Growth,
        PLAN_NAMES.Plus,
      ].includes(this.displayName)
    )
  }
  get isLTFreeUnlocked() {
    // 低于 Free unlock 套餐
    return ![
      PLAN_NAMES.FreeUnlocked,
      PLAN_NAMES.Starter,
      PLAN_NAMES.Growth,
      PLAN_NAMES.Plus,
    ].includes(this.displayName)
  }
  get isLTStarter() {
    // 低于 Starter 套餐
    return ![PLAN_NAMES.Starter, PLAN_NAMES.Growth, PLAN_NAMES.Plus].includes(
      this.displayName,
    )
  }
  get isLTGrowth() {
    // 低于 Growth 套餐
    return ![PLAN_NAMES.Growth, PLAN_NAMES.Plus].includes(this.displayName)
  }
  get isLTPlus() {
    // 低于 Plus 套餐
    return ![PLAN_NAMES.Plus].includes(this.displayName)
  }
  get isGTEFreeEasterEgg() {
    // 高于或等于 Free easter egg 套餐
    return !this.isLTFreeEasterEgg
  }
  get isGTEFreeUnlocked() {
    // 高于或等于 Free unlocked 套餐
    return !this.isLTFreeUnlocked
  }
  get isGTEStarter() {
    // 高于或等于 Starter 套餐
    return !this.isLTStarter
  }
  get isGTEGrowth() {
    // 高于或等于 Growth 套餐
    return !this.isLTGrowth
  }
  get isGTEPlus() {
    // 高于或等于 Plus 套餐
    return !this.isLTPlus
  }
}

let planInstance
export function initGlobalPlan(plan: PlanType) {
  planInstance = new UserPlan(plan)
  return planInstance
}
