import * as Switch from '@radix-ui/react-switch'
import type { SwitchProps } from '@radix-ui/react-switch'
import classNames from 'classnames'

export function SwitchButton(props: SwitchProps) {
  return (
    <Switch.Root
      {...props}
      className={classNames(
        'switch-toot flex h-5 w-8 cursor-pointer items-center rounded-md border-none p-1 transition-colors',
        'data-[state=checked]:bg-[var(--p-color-text)]',
        'data-[state=unchecked]:bg-gray-200',
      )}
      aria-label="Switch"
    >
      <Switch.Thumb
        className={classNames(
          'switch-thumb block h-3 w-3 rounded-[3px] bg-white transition-all',
          'data-[state=checked]:translate-x-3',
          'data-[state=unchecked]:translate-x-0',
        )}
      />
    </Switch.Root>
  )
}
