import AuthApi from '@/api/auth'
import shopApi from '@/api/shop'
import { shopInfoAtom } from '@/atom/shop-info'
import { setSnapshot } from '@/lib/auth/pubsub'
import { $env } from '@/lib/location-env'
import { isEmbedded } from '@/lib/shopify-bridge'
import storageScheme from '@/lib/storage-scheme'
import { getDefaultStore } from 'jotai'

const urlSearch = new URLSearchParams(location.search)
const hmac = urlSearch.get('hmac')
const shop = urlSearch.get('shop')
const timestamp = urlSearch.get('timestamp')
const host = urlSearch.get('host')

const openTarget = isEmbedded() ? '_top' : '_self'
const globalAtomStore = getDefaultStore()

const oAuthState = {
  authenticated: false,
}

/**
 * @return {Promise<boolean>} 是否重新登录成功，否则需要重定向
 */
async function reAuth() {
  if (shop) storageScheme.setItem('shopName', shop)

  const res = await AuthApi.authUser({
    hmac,
    shop,
    timestamp,
    app_id: Number(process.env.PUBLIC_LOLOYAL_APP_ID),
  })

  // 上报authUser接口返回的关键信息
  const data = res.data
  console.log(JSON.stringify(data), Date.now())

  storageScheme.setItem('authUserInfo', JSON.stringify(data))

  if (data.status === 1) {
    // 重定向去安装
    open(data.url, openTarget)
    return false
  }

  if (data.status === 2) {
    // 进入APP
    storageScheme.setItem('loloyal-shop-token', res.data.token)
    // 新手引导完成状态
    storageScheme.setItem('completedGuide', data.completed_guide, 'session')

    // 判断本页面是否由code为500的请求跳转而来的，如果是，则获取完信息之后，跳转回去
    const nowRoute = storageScheme.getItem('nowRoute')
    if (nowRoute) {
      storageScheme.removeItem('nowRoute')
      open(nowRoute, openTarget)
      return false
    }

    // 判断本页面是否由用户从书签进入（无token直接进入页面）
    const firstRoute = storageScheme.getItem('firstRoute')
    if (firstRoute) {
      storageScheme.removeItem('firstRoute')
      open(firstRoute, openTarget)
      return false
    }
    //  else {
    //   open('/', '_top')
    // }

    // 如果不是嵌入模式，直接跳转到对应的页面
    if (!isEmbedded() && (host || shop)) {
      open(
        `https://${host ? atob(host) : shop + '/admin'}/apps/${
          process.env.PUBLIC_SHOPIFY_API_KEY
        }`,
        openTarget,
      )
      return false
    }
    return true
  }

  if (data.status === 3) {
    // 权限不足需要重装
    open(data.url, openTarget)
    return false
  }

  return false
}

/**
 * @return {Promise<boolean>} 是否已经登录
 */
async function checkAuth() {
  if ($env.dashboard) {
    storageScheme.setItem('loloyal-shop-token', $env.dashboardToken)
    const done = await getInitData()
    return done
  }

  if ($env.localhost && !shop) {
    const done = await getInitData()
    return done
  } else {
    const shopToken = storageScheme.getItem('loloyal-shop-token')
    if (!shopToken || (shop && hmac && timestamp && !isEmbedded())) {
      const reAuthDone = await reAuth()
      if (!reAuthDone) return reAuthDone
    }
    const done = await getInitData()
    return done
  }
}

export async function getInitData() {
  let shopInfo = globalAtomStore.get(shopInfoAtom)
  if (!shopInfo) {
    try {
      const res = await shopApi.getShopData()
      globalAtomStore.set(shopInfoAtom, res.data)
    } catch (error) {
      oAuthState.authenticated = false
      const reAuthDone = await reAuth()
      if (!reAuthDone) return reAuthDone
    }
  }
  return true
}

function getToken() {
  if ($env.dashboard) {
    return (
      storageScheme.getItem('loloyal-shop-token') ||
      storageScheme.getItem('shopName')
    )
  }
  if ($env.localhost) {
    let shopName = storageScheme.getItem('shopName')
    if (!shopName) {
      // ! 本地环境测试地址
      shopName = 'bm-ceshi.myshopify.com'
      storageScheme.setItem('shopName', shopName)
    }
    return shopName
  }
  return storageScheme.getItem('loloyal-shop-token')
}

function init() {
  checkAuth().then((done) => {
    if (done) {
      setSnapshot(true)
    }
  })
}

export default {
  init,
  getToken,
  checkAuth,
  state: oAuthState,
}
