import type {
  PopupsGetPopupsInfoResponse,
  PopupsSwitchSettingResponse,
} from '@/api/types'
import client from '@/lib/request'

export default {
  // 全局设置页面数据查询
  popupsDesign(param = {}) {
    return client.post('popups/popupsDesign', param)
  },
  // 全局设置页面修改接口
  updatePopupsDesign(param = {}) {
    return client.post('popups/updatePopupsDesign', param)
  },
  // 获取popup设置页面状态数据
  getPopupsSetting() {
    return client.post<PopupsSwitchSettingResponse>('popups/switchSetting')
  },
  // 更新popup启用状态
  updateSwitchSetting(param = {}) {
    return client.post('popups/updateSwitchSetting', param)
  },
  // 获取 popup 模板信息
  getPopupsInfo(param = {}) {
    return client.post<PopupsGetPopupsInfoResponse>(
      'popups/getPopupsInfo',
      param,
    )
  },
  // 更改 popup 模板信息
  updatePopupsInfo(param = {}) {
    return client.post('popups/updatePopupsInfo', param)
  },
}
