import { requiredBy } from '@/lib/validation/requiredBy'
import { notEmpty, useField } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'

export function useNumberEqualOrThanField(
  defaultValue: number,
  min = 0,
  max = 9999999999,
  isEqual = true,
  enable = true,
) {
  const { t } = useTranslation()

  return useField(
    {
      value: defaultValue, // 初始值
      validates: [
        requiredBy(enable, notEmpty(t('Common.Error.mustHaveQuantity'))),
        (value) => {
          if (!enable) return undefined

          if (isEqual) {
            if (value < min) {
              return t('Common.Input.valueEqualOrThan', { value: min })
            }
            if (value > max) {
              return t('Common.Input.valueEqualOrLess', { value: max })
            }
          } else {
            if (value <= min) {
              return t('Common.Input.valueThan', { value: min })
            }
            if (value >= max) {
              return t('Common.Input.valueLess', { value: max })
            }
          }
        },
      ],
    },
    [enable],
  )
}

type simpleType = string | boolean | number

export function use1To999x(value: number, enable: simpleType = 1) {
  return useNumberEqualOrThanField(value, 1, 9999999999, true, Boolean(enable))
}

export function use0ToBillion(value: number, enable: simpleType = 1) {
  return useNumberEqualOrThanField(value, 0, 100000000, false, Boolean(enable))
}

export function use1To60(value: number, enable: simpleType = 1) {
  return useNumberEqualOrThanField(value, 1, 60, true, Boolean(enable))
}

export function usePercentage(value: number, enable: simpleType = 1) {
  return useNumberEqualOrThanField(value, 0, 100, false, Boolean(enable))
}
