import rewards from '@/api/rewards'
import type { Collection } from '@/components/modules/rule-components/type/order'
import { COUPON_TYPES } from '@/constants/coupon'
import {
  use0ToBillion,
  use1To60,
  use1To999x,
  usePercentage,
} from '@/hooks/common/use-number-field'
import {
  useCollectionField,
  useRequiredField,
} from '@/hooks/common/use-required-field'
import { navigateBack } from '@/lib/navigate-back'
import ShopifyBridge from '@/lib/shopify-bridge'
import { useField, useForm } from '@shopify/react-form'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'

export const DEFAULT_PERCENTAGE_COUPON = {
  amount: 0.1, // 优惠券金额
  collection_id: [] as number[], // 选中的产品系列id
  combines_with: {
    order_discounts: false,
    product_discounts: false,
    shipping_discounts: false,
  }, // 组合优惠券
  coupon_expire_status: 0, // 是否勾选有效期
  coupon_expire_time: 6, //  优惠券有效期
  coupon_type: COUPON_TYPES.Percentage, // 2百分比
  default_icon_id: 1, // 选中图标id
  icon_url: '', // 上传的图标链接
  is_upload_icon: 0, // 是否选用上传图标
  points: 1000, // 积分
  product_range: 1, // 是否设置优惠券应用产品系列
  required: 1, // 是否限制最小金额
  required_mini_amount: 10, // 最小使用金额
  status: 1,
  select_collection: [] as Collection[],
}

export function usePercentageForm(
  id: string | number | undefined,
  amountCoupon: typeof DEFAULT_PERCENTAGE_COUPON,
) {
  const isEdit = !!id
  const { t } = useTranslation()

  const status = useField(amountCoupon.status)
  const coupon_type = useField(amountCoupon.coupon_type)

  const points = use1To999x(amountCoupon.points)
  const amount = usePercentage(amountCoupon.amount * 100)

  const coupon_expire_status = useField(amountCoupon.coupon_expire_status)
  const coupon_expire_time = use1To60(
    amountCoupon.coupon_expire_time,
    coupon_expire_status.value,
  )

  const product_range = useField(amountCoupon.product_range)
  const select_collection = useField(amountCoupon.select_collection ?? [])

  if (amountCoupon.select_collection.length) {
    amountCoupon.collection_id = amountCoupon.select_collection.map(
      (collection) => collection.collection_rid,
    )
  }
  const collection_id = useCollectionField(
    amountCoupon.collection_id ?? [],
    product_range.value,
  )

  const combines_with = useRequiredField(amountCoupon.combines_with)

  const is_upload_icon = useField(amountCoupon.is_upload_icon)
  const default_icon_id = useField(amountCoupon.default_icon_id)
  const icon_url = useField(amountCoupon.icon_url)

  const required = useField(amountCoupon.required)
  const required_mini_amount = use0ToBillion(
    amountCoupon.required_mini_amount,
    required.value === 2,
  )

  const form = useForm({
    fields: {
      amount,
      collection_id,
      select_collection,
      combines_with,
      coupon_expire_status,
      coupon_expire_time,
      coupon_type,
      default_icon_id,
      icon_url,
      is_upload_icon,
      points,
      product_range,
      required,
      required_mini_amount,
      status,
    },
    makeCleanAfterSubmit: true,
    onSubmit: async ({ select_collection, ...values }) => {
      try {
        if (isEdit) {
          const submitData = values
          submitData.amount = submitData.amount / 100
          await rewards.rewardsUpdateCoupon({
            id: Number(id),
            ...submitData,
          })

          ShopifyBridge.toast.show(t('Common.Success.save'))
        } else {
          const submitData = values
          submitData.amount = submitData.amount / 100
          await rewards.createCoupon(submitData)

          ShopifyBridge.toast.show(t('Common.Success.save'))
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              navigateBack('program')
            })
          })
        }
        return {
          status: 'success',
        }
      } catch (e: any) {
        return {
          status: 'fail',
          errors: [
            {
              message: e.message,
            },
          ],
        }
      }
    },
  })

  // useLeaveBlocker(form.dirty)
  return form
}

export const PercentageFormContext = createContext<
  ReturnType<typeof usePercentageForm>
>(null as any)
