import themeTemplateApi from '@/api/themeTemplate'
import type { ThemeDataType } from '@/api/types/themeType'
import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'
import { createContext } from 'react'
import { GrowthToolsSkeleton } from './growth-tools'

export const Route = createFileRoute('/brand-style/widget-panel')({
  loader: async () => {
    await Promise.all([
      i18n.loadNamespaces('brand-style/panel'),
      i18n.loadNamespaces('brand-style/launcher'),
    ])
    return themeTemplateApi
      .selThemeTemplate()
      .then((res: { data: ThemeDataType }) => res.data)
  },
  pendingComponent: () => <GrowthToolsSkeleton />,
})

// 背景图片预览图
export type ThemeConfig = {
  theme_template_id: number
  bar_color: string
  banner_url: string
  pre_icon: string
  title_text_color: string
  button_color: string
  button_text_color: string
  launcher_color: string
}
export let preBgData: ThemeConfig[] = [
  {
    theme_template_id: 0, // blue
    bar_color: '#0064D4',
    banner_url: 'https://static.loloyal.com/i/c_end/theme/blue.webp',
    pre_icon: '/theme/Pre-Blue.png',
    title_text_color: '#FFFFFF',
    button_color: '#0064D4',
    button_text_color: '#FFFFFF',
    launcher_color: '#0064D4',
  },
  {
    theme_template_id: 1, // purple
    bar_color: '#F69DDD',
    banner_url: 'https://static.loloyal.com/i/c_end/theme/purple.webp',
    pre_icon: '/theme/Pre-Purple.png',
    title_text_color: '#000000',
    button_color: '#F69DDD',
    button_text_color: '#FFFFFF',
    launcher_color: '#F69DDD',
  },
  {
    theme_template_id: 2, // green
    bar_color: '#90BAB0',
    banner_url: 'https://static.loloyal.com/i/c_end/theme/green.webp',
    pre_icon: '/theme/Pre-Green.png',
    title_text_color: '#FFFFFF',
    button_color: '#90BAB0',
    button_text_color: '#FFFFFF',
    launcher_color: '#90BAB0',
  },
  {
    theme_template_id: 3, // yellow-golden
    bar_color: '#F5B224',
    banner_url: 'https://static.loloyal.com/i/c_end/theme/yellow.webp',
    pre_icon: '/theme/Pre-Golden.png',
    title_text_color: '#201F37',
    button_color: '#F5B224',
    button_text_color: '#FFFFFF',
    launcher_color: '#F5B224',
  },
  {
    theme_template_id: 4, // brown
    bar_color: '#E0B580',
    banner_url: 'https://static.loloyal.com/i/c_end/theme/brown.webp',
    pre_icon: '/theme/Pre-Orange.png',
    title_text_color: '#303030',
    button_color: '#E0B580',
    button_text_color: '#FFFFFF',
    launcher_color: '#E0B580',
  },
  {
    theme_template_id: 5, // red
    bar_color: '#C20726',
    banner_url: 'https://static.loloyal.com/i/c_end/theme/red.webp',
    pre_icon: '/theme/Pre-Red.png',
    title_text_color: '#FFFFFF',
    button_color: '#C20726',
    button_text_color: '#FFFFFF',
    launcher_color: '#C20726',
  },
  {
    theme_template_id: 6, // blue1-sky blue
    bar_color: '#32ACD7',
    banner_url: 'https://static.loloyal.com/i/c_end/theme/sky_blue.webp',
    pre_icon: '/theme/Pre-Blue1.png',
    title_text_color: '#FFFFFF',
    button_color: '#32ACD7',
    button_text_color: '#FFFFFF',
    launcher_color: '#32ACD7',
  },
  {
    theme_template_id: 99, // customize
    bar_color: '#000000',
    banner_url: '',
    pre_icon: '',
    title_text_color: '#FFFFFF',
    button_color: '#000000',
    button_text_color: '#FFFFFF',
    launcher_color: '#000000',
  },
]
export const PanelContext = createContext({
  preBgData,
})
