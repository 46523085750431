import BillingApi from '@/api/billing'
import dashboardApi from '@/api/dashboard'
import settingApi from '@/api/setting'
import type { HomeDataType } from '@/api/types/dashboard'
import Eggs from '@/assets/images/home/Eggs.png'
import {
  moneyFormatAtom,
  planInfoAtom,
  shopInfoAtom,
  userInfoAtom,
  userPlanInstanceAtom,
} from '@/atom/shop-info'
import { CommentBanner } from '@/components/common/comment/CommentBanner'
import type { InputOutputType } from '@/components/common/date-picker/DateRangePicker'
import { DateRangePicker } from '@/components/common/date-picker/DateRangePicker'
import { BrandingFooter } from '@/components/common/footer/BrandingFooter'
import { LanguageSelect } from '@/components/common/language-select'
import { TotalDataCard } from '@/components/modules/analytics/TotalDataCard'
import { FakeButton } from '@/components/modules/billing/FakeButton'
import {
  CurrentPlan,
  HomeModal,
  NeedHelp,
  QuickGuide,
} from '@/components/modules/home'
import { ModuleCard } from '@/components/ui/card/ModuleCard'
import { SwitchButton } from '@/components/ui/selection/SwitchButton'
import { PLAN_NAMES } from '@/constants/keys'
import i18n from '@/i18n'
import { addThousandSeparator } from '@/lib/number'
import { initGlobalPlan } from '@/lib/plan'
import ShopifyBridge from '@/lib/shopify-bridge'
import {
  BlockStack,
  Box,
  Card,
  InlineGrid,
  InlineStack,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Spinner,
  Text,
} from '@shopify/polaris'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { produce } from 'immer'
import { useAtom, useAtomValue } from 'jotai'
import { Suspense, lazy, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const RecommendSwipe = lazy(
  () => import('@/components/modules/home/RecommendSwipe'),
)

export const Route = createFileRoute('/')({
  loader: async () =>
    Promise.all([i18n.loadNamespaces('home'), i18n.loadNamespaces('billing')]),
  component: Home,
  pendingComponent: SkeletonHome,
})

function SkeletonHome() {
  const titleList = new Array(3).fill(1)
  const contentList = new Array(6).fill(1)
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap={'400'}>
            <InlineGrid gap="400" columns={3}>
              {titleList.map((item, index) => (
                <Card key={index}>
                  <Text as="dd">
                    <Box paddingBlockEnd="400">
                      <SkeletonDisplayText size="small" />
                    </Box>
                    <SkeletonBodyText lines={1} />
                  </Text>
                </Card>
              ))}
            </InlineGrid>
            <BlockStack gap={'400'}>
              {contentList.map((item, index) => (
                <Card key={index}>
                  <Text as="dd">
                    <Box paddingBlockEnd="400">
                      <SkeletonDisplayText size="small" />
                    </Box>
                    <SkeletonBodyText lines={1} />
                  </Text>
                </Card>
              ))}
            </BlockStack>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
function Home() {
  const { t } = useTranslation()
  const { t: homeT } = useTranslation('home')
  const eggsTimes = useRef(0)
  const moneyFormat = useAtomValue(moneyFormatAtom)
  const userPlanInstance = useAtomValue(userPlanInstanceAtom)
  const [shopInfo, setShopInfo] = useAtom(shopInfoAtom)
  const [userInfo, setUserInfo] = useAtom(userInfoAtom)
  const [planInfo, setPlanInfo] = useAtom(planInfoAtom)
  const [eggsOrder, setEggsOrder] = useState<number>(80)
  const [widgetText, setWidgetText] = useState(
    `${homeT('widget')} ${homeT('display')}`,
  )
  const [datePickerTime, setDatePickerTime] = useState({
    since: userInfo.created_at,
    until: dayjs().endOf('day').unix(),
  })
  const [isShowDialog, setIsShowDialog] = useState(false)
  const isShowEggsReview =
    planInfo.is_easter_eggs && userInfo.feedback_status === 1
  const isFree =
    planInfo.display_name === PLAN_NAMES.Free && !planInfo.is_easter_eggs

  let isShowEggsModule = false
  if (userPlanInstance.isFree) {
    // free版本展示
    isShowEggsModule = true
  } else if (userPlanInstance.isFreeEasterEgg) {
    // free eggs版本按条件展示
    userInfo.feedback_status === 1
      ? (isShowEggsModule = true)
      : (isShowEggsModule = false)
  }
  const fireStart = () => {
    const end = Date.now() + 2 * 1000
    // const colors = ['#bb0000', '#00bbff'];
    import('canvas-confetti').then(({ default: confetti }) => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(function frame() {
        confetti({
          particleCount: 3,
          angle: 60,
          spread: 55,
          origin: {
            x: 0,
          },
          // colors: colors
        })
        confetti({
          particleCount: 3,
          angle: 120,
          spread: 55,
          origin: {
            x: 1,
          },
        })

        if (Date.now() < end) {
          requestAnimationFrame(frame)
        }
      })()
    })
  }
  // 更改用户新人状态
  const updFirstEntry = () => {
    dashboardApi.updFirstEntry().then((res) => {
      setShopInfo(
        produce(shopInfo, (draft) => {
          draft!.first_entry = res.data.first_entry
        }),
      )
    })
  }
  const {
    data: homeData,
    isLoading,
    isRefetching,
    error,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['getAllData', datePickerTime],
    queryFn: async () => {
      return dashboardApi
        .getHomeData({
          start_time: datePickerTime.since,
          end_time: datePickerTime.until,
        })
        .then((res: { data: HomeDataType }) => {
          return res.data
        })
    },
  })
  useEffect(() => {
    if (homeData) {
      setWidgetText(
        homeData.shopSettingData.clientStatus
          ? `${homeT('widget')} ${homeT('display')}`
          : `${homeT('widget')} ${homeT('hidden')}`,
      )
    }
    if (shopInfo?.first_entry === 1) {
      setIsShowDialog(true)
      fireStart()
      updFirstEntry()
    }
  }, [homeT])
  if (!homeData || isLoading || error) {
    return <SkeletonHome />
  }
  const TotalData = [
    {
      key: 'order_paid', // 用于数据匹配
      title: t('Analytics.orderPaidTitle'),
      titleDesc: t('Analytics.orderPaidTitleDesc'),
      showNumber: '0',
    },
    {
      key: 'points_earn',
      title: t('Analytics.pointsEarnTitle'),
      titleDesc: t('Analytics.pointsEarnTitleDesc'),
      showNumber: '0',
    },
    {
      key: 'members',
      title: t('Analytics.membersTitle'),
      titleDesc: t('Analytics.membersTitleDesc'),
      showNumber: '0',
    },
  ]
  let totalArrData = Object.values(homeData.dataStatistics)
  // 第一个是货币符号，不参与计算
  totalArrData.shift()
  // 去除数组中的null
  totalArrData = totalArrData.map((item) => {
    return String(item || 0)
  })
  // 第一位金额数字保留2位小数
  totalArrData[0] = Number(totalArrData[0]).toFixed(2)
  // 数字展示卡片赋值
  TotalData.forEach((item, index) => {
    let cardNumber = addThousandSeparator(totalArrData[index])
    if ([0].includes(index)) {
      cardNumber = `${moneyFormat} ${cardNumber}`
    }
    TotalData[index].showNumber = cardNumber
  })
  const startDate = t('Common.Date.allTime')
  const sinceDate = dayjs().startOf('day')
  const untilDate = dayjs().endOf('day')
  const selectRange = [
    {
      title: t('Common.Date.allTime'),
      alias: 'allTime',
      period: {
        since: dayjs.unix(userInfo.created_at).toDate(),
        until: untilDate.toDate(),
      },
    },
    {
      title: t('Common.Date.today'),
      alias: 'today',
      period: {
        since: sinceDate.toDate(),
        until: untilDate.toDate(),
      },
    },
    {
      title: t('Common.Date.lastDays', { days: 7 }),
      alias: 'last7Days',
      period: {
        since: sinceDate.subtract(7, 'day').toDate(),
        until: untilDate.subtract(1, 'day').toDate(),
      },
    },
    {
      title: t('Common.Date.lastDays', { days: 30 }),
      alias: 'last30Days',
      period: {
        since: sinceDate.subtract(30, 'day').toDate(),
        until: untilDate.subtract(1, 'day').toDate(),
      },
    },
    {
      title: t('Common.Date.lastDays', { days: 90 }),
      alias: 'last90Days',
      period: {
        since: sinceDate.subtract(90, 'day').toDate(),
        until: untilDate.subtract(1, 'day').toDate(),
      },
    },
  ]
  const updatedBenefits = () => {
    BillingApi.getPlanBenefits().then((res) => {
      initGlobalPlan(res.data)
      setPlanInfo(res.data.plan)
    })
  }
  const eggsClick = (e: any) => {
    eggsTimes.current++
    if (eggsTimes.current < 2 && isFree) {
      dashboardApi.activeEasterEggs().then((res) => {
        import('canvas-confetti').then(({ default: confetti }) => {
          confetti({
            particleCount: 80,
            spread: 90,
            origin: {
              x: e.clientX / innerWidth,
              y: e.clientY / innerHeight,
            },
          })
        })
        const planData = res.data
        setEggsOrder(planData.order_limit || 80)
        updatedBenefits()
        setUserInfo(
          produce(userInfo, (draft) => {
            draft.feedback_status = planData.feedback_status
          }),
        )
      })
    }
  }
  const reviewClick = () => {
    dashboardApi.updFeedbackStatus().then((res) => {
      setUserInfo(
        produce(userInfo, (draft) => {
          draft.feedback_status = res.data.feedback_status
        }),
      )
    })
  }
  const checkedHandle = (value: boolean) => {
    setWidgetText(
      value
        ? `${homeT('widget')} ${homeT('display')}`
        : `${homeT('widget')} ${homeT('hidden')}`,
    )
    settingApi
      .updatePointsStatus({
        status: value ? 1 : 0,
      })
      .then((res) => {
        ShopifyBridge.toast.show(t('Common.Success.message'))
      })
      .catch(() => {
        ShopifyBridge.toast.show(t('Common.Error.failed'))
      })
  }
  const onDateChange = (data: InputOutputType) => {
    if (data.until) {
      const since = dayjs(data.since).startOf('day').unix()
      const until = dayjs(data.until).endOf('day').unix()
      setDatePickerTime({
        since,
        until,
      })
    }
  }

  return (
    <Page>
      <BlockStack gap={'400'}>
        <div className="px-4 md:px-0">
          <InlineStack blockAlign="center" gap="300" align="space-between">
            <Text as="p" fontWeight="bold">
              <span className="text-[20px]">{`${homeT('greeting')} ${userInfo.name}`}</span>
            </Text>
            <InlineStack blockAlign="center" gap={'400'}>
              <InlineStack blockAlign="center" gap={'200'}>
                <SwitchButton
                  defaultChecked={Boolean(
                    homeData.shopSettingData.clientStatus,
                  )}
                  onCheckedChange={checkedHandle}
                />
                <Text as="p" fontWeight="bold">
                  <span className="text-[16px]">{widgetText}</span>
                </Text>
              </InlineStack>
              <LanguageSelect />
            </InlineStack>
          </InlineStack>
        </div>
        <CommentBanner />

        <BlockStack gap={'300'}>
          <InlineStack blockAlign="center" align="space-between">
            <Text as="p" fontWeight="bold">
              <span className="text-[14px]">{homeT('performance')}</span>
            </Text>
            <InlineStack gap="200" blockAlign="center">
              {isRefetching && <Spinner size="small"></Spinner>}
              <DateRangePicker
                selectRange={selectRange}
                startDate={startDate}
                onDateChange={onDateChange}
              />
            </InlineStack>
          </InlineStack>
          <div className="gap-4 md:flex">
            {TotalData.map((item, index) => (
              <div
                className={classNames(
                  'md:mt-0 md:w-1/3',
                  index > 0 ? 'mt-3' : '',
                )}
                key={index}
              >
                <TotalDataCard {...item} key={index}></TotalDataCard>
              </div>
            ))}
          </div>
        </BlockStack>
        {isShowEggsModule && (
          <ModuleCard title={homeT('easterEggs')}>
            <InlineStack blockAlign="center" align="space-between">
              <InlineStack blockAlign="center" gap={'400'}>
                <Box>
                  <img className="h-8 w-8" src={Eggs} alt="eggs" />
                </Box>
                <BlockStack>
                  <Text as="p" fontWeight="bold">
                    <span className="text-[16px]">
                      {!planInfo.is_easter_eggs
                        ? homeT('limitedTimeEasterEgg')
                        : homeT('congratulations', { order: eggsOrder })}
                    </span>
                  </Text>
                  <Text as="p" tone="subdued">
                    <span className="text-[14px]">
                      {!planInfo.is_easter_eggs
                        ? homeT('unlockOrderUsage')
                        : userInfo.feedback_status === 1
                          ? homeT('feedbackALotToUs')
                          : ''}
                    </span>
                  </Text>
                </BlockStack>
              </InlineStack>
              {isFree && eggsTimes.current < 2 && (
                <FakeButton
                  className="bg-loloyal-primary"
                  onClick={(e) => {
                    eggsClick(e)
                  }}
                >
                  <Text as="p">
                    <span className="text-[12px] text-white">
                      {homeT('clickTwice')}
                    </span>
                  </Text>
                </FakeButton>
              )}
              {isShowEggsReview && (
                <InlineStack blockAlign="center" align="space-between">
                  <div className="w-[100px]">
                    <Text as="p" fontWeight="bold">
                      <span>☹️&nbsp;</span>
                      <span
                        onClick={reviewClick}
                        className="cursor-pointer text-[14px] text-[#9D9CAF] hover:text-loloyal-primary hover:underline"
                      >
                        {homeT('bad')}
                      </span>
                    </Text>
                  </div>
                  <div className="w-[100px]">
                    <Text as="p" fontWeight="bold">
                      <span>😊&nbsp;</span>
                      <span
                        onClick={() => {
                          reviewClick()
                          window.open(
                            'https://apps.shopify.com/loloyal#modal-show=WriteReviewModal',
                          )
                        }}
                        className="cursor-pointer text-[14px] text-[#9D9CAF] hover:text-loloyal-primary hover:underline"
                      >
                        {homeT('good')}
                      </span>
                    </Text>
                  </div>
                </InlineStack>
              )}
            </InlineStack>
          </ModuleCard>
        )}
        <CurrentPlan></CurrentPlan>
        <QuickGuide></QuickGuide>
        <Suspense
          fallback={<ModuleCard title={homeT('recommended')}></ModuleCard>}
        >
          <RecommendSwipe />
        </Suspense>
        <NeedHelp></NeedHelp>
      </BlockStack>
      <BrandingFooter />
      <HomeModal
        open={isShowDialog}
        onConfirm={() => setIsShowDialog(false)}
      ></HomeModal>
    </Page>
  )
}
