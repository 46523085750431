import auth from '@/lib/auth/oAuth'

const listeners: Array<() => void> = []
export function subscribe(callback: () => void) {
  listeners.push(callback)

  if (auth.state.authenticated) {
    callback()
  }

  return () => {
    const index = listeners.indexOf(callback)
    if (index !== -1) {
      listeners.splice(index, 1)
    }
  }
}

export function getSnapshot() {
  return auth.state.authenticated
}

export function setSnapshot(value: boolean) {
  auth.state.authenticated = value
  listeners.forEach((listener) => {
    listener()
  })
}
