import client from '@/lib/request'

export default {
  // 更改顾客参与忠诚计划条件
  updateParticipants(param: { participants: number }) {
    return client.post('updParticipantsSetting', param)
  },
  // 更改 B 端多语言
  updMerchantLanguage(param: { language: string }) {
    return client.post('updMerchantLanguage', param)
  },
}
