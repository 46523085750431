import themeTemplate from '@/api/themeTemplate'
import { createFileRoute } from '@tanstack/react-router'
import { createContext } from 'react'

export const Route = createFileRoute('/brand-style/')({
  loader: () => themeTemplate.selThemeTemplate().then((res) => res.data),
})

export const formLikeContext = createContext<null | {
  save: () => void
  dirty: boolean
  hasError: boolean
  submitting: boolean
}>(null)
