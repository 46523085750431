import { POINT_RULE_ID } from '@/constants/keys'

export const getRuleDefault = (rule_id: number) => {
  switch (rule_id) {
    case POINT_RULE_ID.review:
      return {
        status: 1,
        photos_amount: 100,
        text_amount: 100,
        limit_times: 100,
        frequency: 3,
        has_photos: 0,
        is_limit: 0,
        review_app_id: 0,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.order:
      return {
        amount: 1,
        points: 100,
        status: 1,
        // 不勾选earnProgress=1 下单立即送积分 | 勾选后earnProgress=2 订单完成送积分
        earn_progress: 1,
        // 下单赠送积分规则  1按比例递增 2固定金额
        earn_type: 1,
        // 选择所有产品可获得积分1 固定产品系列可获得积分2
        apply_to: 1,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
        collection_id: [],
        select_collection: [],
      }
      break
    case POINT_RULE_ID.signUp:
      return {
        status: 1,
        points: 100,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.birthday:
      return {
        points: 100,
        status: 1,
        validity_period: 1,
        before_days: 7,
        after_days: 7,
        advance_setting: 0,
        advance_days: 30,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.subscribe:
      return {
        status: 1,
        points: 100,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.facebook:
      return {
        status: 1,
        points: 100,
        time: 100,
        url: '',
        is_refresh: 0,
        share_frequency: 3,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.twitter:
      return {
        status: 1,
        points: 100,
        time: 100,
        url: '',
        message: '',
        is_refresh: 0,
        share_frequency: 3,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.instagram:
      return {
        status: 1,
        points: 100,
        name: '',
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.tikTok:
      return {
        status: 1,
        points: 100,
        name: '',
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.visitWebsite:
      return {
        status: 1,
        points: 100,
        frequency: 1,
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    case POINT_RULE_ID.youtube:
      return {
        status: 1,
        points: 100,
        name: '',
        default_icon_id: 1,
        file_name: '',
        icon_url: '',
        is_upload_icon: 0,
      }
      break
    default:
      return {}
  }
}
