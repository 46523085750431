import type { ResponseWrapper } from '@/api/types'
import auth from '@/lib/auth/oAuth'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
// import { bannerBridge } from '@/utils/banner-bridge'

const URL = process.env.PUBLIC_LOLOYAL_API_URL

const client = axios.create({
  baseURL: URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
})

// 对请求前的拦截器
client.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = auth.getToken()
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// 对响应的拦截器
client.interceptors.response.use(
  (response: AxiosResponse<ResponseWrapper>) => {
    // if (response.config.url) {
    //   const { url } = response.config
    //   if (response.data.code === 1000) {
    //     bannerBridge.triggerRemove(url)
    //   }
    //   if (response.data.code === 1000007) {
    //     bannerBridge.triggerAdd(url, response.data.message || response.data.msg)
    //   }
    // }
    if (response.data?.code && response.data.code !== 200) {
      if (response.data.code === 505 && auth.state.authenticated) {
        // 已经在登录状态的情况下，token失效
        auth.checkAuth().then(() => {
          shopify.toast.show(
            'Login expired, please try again. will reload after 3 seconds',
          )
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
      }
      return Promise.reject(response.data)
    }

    if (response.data.data && response.data.success) {
      response.data = response.data.data
    } else if (!response.data.data) return response
    else Promise.reject(response.data)

    return response
  },
  (error: any) => {
    return Promise.reject(error)
  },
)

export default client
