import BillingApi from '@/api/billing'
import { BlockStack, Card, Page, SkeletonBodyText } from '@shopify/polaris'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/guidance/billing/')({
  loader: async () => {
    const { data } = await BillingApi.getSubscribePlan()
    return data
  },
  pendingComponent: () => (
    <Page>
      <Card>
        <BlockStack gap="200">
          <SkeletonBodyText lines={20} />
        </BlockStack>
      </Card>
    </Page>
  ),
})
