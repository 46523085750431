type BenefitType = {
  benefitKey: string
  value: boolean | number
  apply: string
}

export class Benefit {
  benefitKey: string
  value: boolean | number
  apply: string
  constructor(benefit: BenefitType) {
    this.benefitKey = benefit.benefitKey
    this.value = benefit.value
    this.apply = benefit.apply
  }

  hasPermission(count?: number) {
    return this.value
  }

  applyToPlan() {
    return this.apply
  }
}

export class OrderLimitBenefit extends Benefit {
  hasPermission(orderUsageCount: number) {
    if (this.value === -1) {
      return true
    }
    if (typeof this.value === 'number') {
      return this.value > orderUsageCount
    }
    return false
  }
}
