import React, { useEffect } from 'react'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/test/')({
  component: TestPage,
})
export function TestPage() {
  useEffect(() => {})

  return <div id="editor">2231</div>
}
