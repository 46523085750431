import referral from '@/api/referral'
import rewards from '@/api/rewards'
import { SkeletonInlineGrid } from '@/components/ui/skeleton'
import i18n from '@/i18n'
import type { FileBaseRouteOptions } from '@tanstack/react-router'

type RouteOptions = FileBaseRouteOptions & {
  pendingComponent: () => JSX.Element
}

/**
 * 生成优惠券路由的模板
 */
export const composeCouponRouteTemplate = (defaultCoupon: any): any => {
  return {
    validateSearch: (search: { id?: string }) => {
      return {
        id: search.id,
      }
    },
    loaderDeps: (ctx: any) => {
      return {
        id: ctx.search.id,
      }
    },
    loader: async ({ deps }: any) => {
      if (!deps.id) {
        await i18n.loadNamespaces('coupon')
        return defaultCoupon
      }
      const [, coupon] = await Promise.all([
        i18n.loadNamespaces('coupon'),
        rewards.queryCouponById(deps).then((res) => res.data),
      ])
      return coupon
    },
    pendingComponent: () => <SkeletonInlineGrid></SkeletonInlineGrid>,
  }
}

/**
 * 生成 Referral 优惠券路由的模板
 */
export const composeReferralCouponRouteTemplate = (defaultCoupon: any): any => {
  return {
    validateSearch: (search: {
      isEdit?: '✔'
      type?: 'referrer' | 'referee'
    }) => {
      return {
        isEdit: search.isEdit,
        type: search.type || 'referrer',
      }
    },
    loaderDeps: (ctx: any) => {
      return {
        referral_type: ctx.search.type === 'referrer' ? 1 : 2,
        isEdit: ctx.search.isEdit,
      }
    },
    loader: async ({ deps }: any) => {
      if (!deps.isEdit) {
        await i18n.loadNamespaces('coupon')
        return defaultCoupon
      }
      const [, coupon] = await Promise.all([
        i18n.loadNamespaces('coupon'),
        referral
          .searchReferralReward({
            referral_type: deps.referral_type,
          })
          .then((res) => res.data),
      ])
      return coupon
    },
    pendingComponent: () => <SkeletonInlineGrid></SkeletonInlineGrid>,
  }
}
