import {
  Box,
  Button,
  DatePicker,
  InlineGrid,
  OptionList,
  Popover,
  Scrollable,
  useBreakpoints,
} from '@shopify/polaris'
import { CalendarIcon } from '@shopify/polaris-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface PeriodType {
  since: Date
  until: Date
}
export interface DatePickerOutputType {
  start: string
  end: string
}
export interface InputOutputType {
  since: string
  until: string
}
export interface DataInfoType {
  title: string
  alias: string
  period: PeriodType
}
export interface DateParamsType {
  selectRange?: DataInfoType[]
  defaultBtnValue?: string
  startDate?: string
  endDate?: string
  disableDatesBefore?: Date
  disableDatesAfter?: Date
  onDateChange?: (data: InputOutputType) => void
}

const today = new Date(new Date().setHours(0, 0, 0, 0))
const yesterday = new Date(
  new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0),
)
function getThisWeekRange() {
  const today = new Date()
  const dayOfWeek = today.getDay()
  const sunday = new Date(today)
  sunday.setDate(today.getDate() - dayOfWeek)
  const saturday = new Date(today)
  saturday.setDate(today.getDate() + (6 - dayOfWeek))
  sunday.setHours(0, 0, 0, 0)
  saturday.setHours(0, 0, 0, 0)
  // 如果范围的结尾在今天之后，将其设置为今天
  const endOfWeek = new Date(saturday)
  if (endOfWeek > today) {
    endOfWeek.setDate(today.getDate())
  }
  return { sunday, endOfWeek }
}
function getThisMonthRange() {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  firstDayOfMonth.setHours(0, 0, 0, 0)
  lastDayOfMonth.setHours(0, 0, 0, 0)
  const endOfMonth = new Date(lastDayOfMonth)
  if (endOfMonth > today) {
    endOfMonth.setDate(today.getDate())
  }
  return { firstDayOfMonth, endOfMonth }
}
function getLastMonthRange() {
  const today = new Date()
  const firstDayOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1,
  )
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
  firstDayOfLastMonth.setHours(0, 0, 0, 0)
  lastDayOfLastMonth.setHours(0, 0, 0, 0)
  return { firstDayOfLastMonth, lastDayOfLastMonth }
}
function formatDateToYearMonthDayDateString(date: Date) {
  const year = String(date.getFullYear())
  let month = String(date.getMonth() + 1)
  let day = String(date.getDate())
  if (month.length < 2) {
    month = String(month).padStart(2, '0')
  }
  if (day.length < 2) {
    day = String(day).padStart(2, '0')
  }
  return [year, month, day].join('-')
}
function formatDate(date: Date) {
  return formatDateToYearMonthDayDateString(date)
}

export const DateRangePicker = ({
  selectRange,
  defaultBtnValue,
  startDate,
  endDate,
  disableDatesBefore,
  disableDatesAfter,
  onDateChange,
}: DateParamsType) => {
  const { t } = useTranslation()
  const rangeDate = [
    {
      title: t('Reward.today'),
      alias: 'today',
      period: {
        since: today,
        until: today,
      },
    },
    {
      title: t('Reward.yesterday'),
      alias: 'yesterday',
      period: {
        since: yesterday,
        until: yesterday,
      },
    },
    {
      title: t('Reward.thisWeek'),
      alias: 'thisWeek',
      period: {
        since: getThisWeekRange().sunday,
        until: getThisWeekRange().endOfWeek,
      },
    },
    {
      title: t('Reward.thisMonth'),
      alias: 'thisMonth',
      period: {
        since: getThisMonthRange().firstDayOfMonth,
        until: getThisMonthRange().endOfMonth,
      },
    },
    {
      title: t('Reward.lastMonth'),
      alias: 'lastMonth',
      period: {
        since: getLastMonthRange().firstDayOfLastMonth,
        until: getLastMonthRange().lastDayOfLastMonth,
      },
    },
  ]
  const { mdDown, lgUp } = useBreakpoints()
  const shouldShowMultiMonth = lgUp
  const ranges: DataInfoType[] = selectRange || rangeDate
  const [buttonValue, setButtonValue] = useState(defaultBtnValue)
  const [popoverActive, setPopoverActive] = useState(false)
  const [activeDateRange, setActiveDateRange] = useState(ranges[0])
  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.since.getMonth(),
    year: activeDateRange.period.since.getFullYear(),
  })
  function handleMonthChange(month: number, year: number) {
    setDate({ month, year })
  }
  function handleCalendarChange({ start, end }: { start: Date; end: Date }) {
    const newDateRange: DataInfoType = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      )
    }) || {
      title: t('Reward.custom'),
      alias: 'custom',
      period: {
        since: start,
        until: end,
      },
    }
    setActiveDateRange(newDateRange)
    setButtonValue(
      newDateRange.title === t('Reward.custom')
        ? newDateRange.period.since.toDateString() +
            ' - ' +
            newDateRange.period.until.toDateString()
        : newDateRange.title,
    )
    // setPopoverActive(!popoverActive)
    if (onDateChange) {
      onDateChange({
        since: formatDate(newDateRange.period.since),
        until: formatDate(newDateRange.period.until),
      })
    }
  }

  useEffect(() => {
    if (startDate) {
      const result = ranges.find((range) => range.title === startDate)
      if (result) {
        setActiveDateRange(result)
        handleCalendarChange({
          start: result.period.since,
          end: result.period.until,
        })
        return
      }
    }
    if (startDate && endDate) {
      const startYear = parseInt(startDate.split('-')[0])
      const startMonth = parseInt(startDate.split('-')[1]) - 1
      const startDay = parseInt(startDate.split('-')[2])
      const endYear = parseInt(endDate.split('-')[0])
      const endMonth = parseInt(endDate.split('-')[1]) - 1
      const endDay = parseInt(endDate.split('-')[2])
      handleCalendarChange({
        start: new Date(startYear, startMonth, startDay),
        end: new Date(endYear, endMonth, endDay),
      })
    }
  }, [startDate, endDate])

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button
          size="slim"
          icon={CalendarIcon}
          onClick={() => setPopoverActive(!popoverActive)}
        >
          {buttonValue}
        </Button>
      }
      onClose={() => setPopoverActive(false)}
    >
      <Popover.Pane fixed>
        <InlineGrid
          columns={{
            xs: '1fr',
            md: 'max-content max-content',
          }}
        >
          <Box
            maxWidth={mdDown ? '516px' : '212px'}
            width={mdDown ? '100%' : '212px'}
            padding={{ xs: '500', md: '0' }}
            paddingBlockEnd={{ xs: '100', md: '0' }}
          >
            <Scrollable style={{ height: '334px' }}>
              <OptionList
                options={ranges.map((range) => ({
                  value: range.alias,
                  label: range.title,
                }))}
                selected={[activeDateRange.alias]}
                onChange={(value) => {
                  const result = ranges.find(
                    (range) => range.alias === value[0],
                  ) as DataInfoType
                  setButtonValue(
                    result.title === t('Reward.custom')
                      ? result.period.since.toDateString() +
                          ' - ' +
                          result.period.until.toDateString()
                      : result.title,
                  )
                  setPopoverActive(!popoverActive)
                  if (onDateChange) {
                    onDateChange({
                      since: formatDate(result.period.since),
                      until: formatDate(result.period.until),
                    })
                  }
                  setActiveDateRange(result)
                  handleMonthChange(
                    result.period.since.getMonth(),
                    result.period.since.getFullYear(),
                  )
                }}
              />
            </Scrollable>
          </Box>
          <Box padding={{ xs: '500' }} maxWidth={mdDown ? '320px' : '516px'}>
            <DatePicker
              month={month}
              year={year}
              selected={{
                start: activeDateRange.period.since,
                end: activeDateRange.period.until,
              }}
              onMonthChange={handleMonthChange}
              onChange={handleCalendarChange}
              disableDatesBefore={disableDatesBefore}
              disableDatesAfter={disableDatesAfter}
              multiMonth={shouldShowMultiMonth}
              allowRange
            />
          </Box>
        </InlineGrid>
      </Popover.Pane>
    </Popover>
  )
}
