// key：字段名称 value：
export enum BENEFIT_KEYS {
  order_limit = 'order_limit',
  vip_program = 'vip_program',
  email_notifications = 'email_notifications',
  loloyal_popup = 'loloyal_popup',
  theme_fonts = 'theme_fonts',
  referrals_program = 'referrals_program',
  shopify_pos = 'shopify_pos',
  limited_time_offers = 'limited_time_offers',
  loloyal_link = 'loloyal_link',
  theme_panel_visibility_and_order = 'theme_panel_visibility_and_order',
  customize_theme = 'customize_theme',
  multi_languages = 'multi_languages',
  program_analytics_overview = 'program_analytics_overview',
  integrations_trust_too = 'integrations_trust_too',
  integrations_judge_me = 'integrations_judge_me',
  integrations_klaviyo = 'integrations_klaviyo',
  free_product_coupon = 'free_product_coupon',
  free_shipping_coupon = 'free_shipping_coupon',
  points_write_review = 'points_write_review',
  celebrate_birthday = 'celebrate_birthday',
  points_expiration = 'points_expiration',
  rewards_expiration = 'rewards_expiration',
  widget_launcher_shape = 'widget_launcher_shape',
  widget_launcher_upload_icon = 'widget_launcher_upload_icon',
  theme_banner_image = 'theme_banner_image',
  theme_store_logo = 'theme_store_logo',
}
