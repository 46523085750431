import general$ from '@/api/general'
import GlobeEU from '@/assets/icons/GlobeEU.svg'
import { shopInfoAtom } from '@/atom/shop-info'
import i18n from '@/i18n'
import { locales } from '@/i18n/languages'
import storageScheme from '@/lib/storage-scheme'
import { Box, Select } from '@shopify/polaris'
import { produce } from 'immer'
import { useAtom } from 'jotai'
import { useState } from 'react'

type PropsType = {
  width?: number
  onSelectChange?: (value: string) => void
}

const LanguageIcon = () => {
  return (
    <div className="h-5 w-5">
      <img className="h-5 w-5" src={GlobeEU} alt="en" />
    </div>
  )
}

export const LanguageSelect = (props: PropsType) => {
  const [shopInfo, setShopInfo] = useAtom(shopInfoAtom)

  const [fetching, setFetching] = useState(false)

  const handleChange = (value: string) => {
    i18n.changeLanguage(value)
    storageScheme.setItem('loloyal-language', value)
    updShopInfo(value)
  }

  const updShopInfo = (value: string) => {
    if (!fetching) {
      setFetching(true)
      const newShopInfo = produce(shopInfo, (draft) => {
        draft!.userInfo.language = value
      })
      setShopInfo(newShopInfo)
      general$
        .updMerchantLanguage({ language: value })
        .then((res) => {
          setFetching(false)
        })
        .catch((e: any) => {
          setFetching(false)
        })
    }
  }

  locales.forEach((locale) => {
    locale.prefix = <LanguageIcon />
  })

  return (
    <Box as="div" width={`${props.width ? props.width : 156}px`}>
      <Select
        label="language select"
        labelHidden
        options={locales}
        onChange={(value) => handleChange(value)}
        value={shopInfo?.userInfo.language}
      />
    </Box>
  )
}
