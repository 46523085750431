import type { MouseEventHandler } from 'react'

export const FakeButton = ({
  disabled,
  children,
  onClick,
  className,
}: {
  children: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  disabled?: boolean
}) => {
  return (
    <button
      className={`${className} cursor-pointer rounded-150 border-none bg-[#D4D4D4] px-[10px] py-[5px] brightness-100 transition-all hover:shadow-md hover:brightness-110 active:brightness-100 `}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
