export function alphabetMatch(val: string) {
  // 正则表达式
  const regexSet = {
    themeACProfile: /[a-c]/i,
    themeDFProfile: /[d-f]/i,
    themeGIProfile: /[g-i]/i,
    themeJLProfile: /[j-l]/i,
    themeMOProfile: /[m-o]/i,
    themePRProfile: /[p-r]/i,
    themeSUProfile: /[s-u]/i,
    themeVXProfile: /[v-x]/i,
    themeYZProfile: /[y-z]/i,
  } as const

  return (
    Object.keys(regexSet).find((item) =>
      regexSet[item as keyof typeof regexSet].test(val),
    ) || 'themeYZProfile'
  )
}
