import { ModuleCard } from '@/components/ui/card/ModuleCard'
import { BlockStack, Box, InlineStack, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import Message from '@/assets/icons/Message.svg'
import HelpCenter from '@/assets/icons/HelpCenter.svg'
import Demo from '@/assets/icons/Demo.svg'
import Gwen from '@/assets/images/home/Gwen.png'
import Anne from '@/assets/images/home/Anne.webp'
import Derek from '@/assets/images/home/Derek.png'
import { useNavigate } from '@tanstack/react-router'

export const NeedHelp = () => {
  const { t: homeT } = useTranslation('home')
  const navigate = useNavigate()
  const needHelpLCard = [
    {
      title: homeT('sendMessage') + '  >',
      desc: homeT('supportTeam'),
      iconName: Message,
      clickHandle: () => {
        window.Willdesk?.chat('')
      },
    },
    {
      title: homeT('helpCenter') + '  >',
      desc: homeT('selfStarter'),
      iconName: HelpCenter,
      clickHandle: () => {
        window.open('https://help.loloyal.com/')
      },
    },
    {
      title: homeT('getADemo') + '  >',
      desc: homeT('liveDemo'),
      iconName: Demo,
      clickHandle: () => {
        // 打开会议
        navigate({
          to: '/platform/calendly',
          search: {
            from: 'home',
          },
        })
      },
    },
  ]
  const needHelpRCard = [
    {
      title: homeT('gwen'),
      desc: homeT('customerServiceSpecialist'),
      iconName: Gwen,
    },
    {
      title: homeT('anne'),
      desc: homeT('customerSuccessManager'),
      iconName: Anne,
    },
    {
      title: homeT('derek'),
      desc: homeT('productManager'),
      iconName: Derek,
    },
  ]
  return (
    <ModuleCard title={homeT('needHelp')}>
      <div className="md:flex md:items-center">
        <BlockStack gap={'400'}>
          {needHelpLCard.map((item, index) => (
            <InlineStack key={index} blockAlign="center" gap={'400'}>
              <Box>
                <img
                  onClick={item.clickHandle}
                  className="h-10 w-10 cursor-pointer"
                  src={item.iconName}
                  alt="help"
                />
              </Box>
              <BlockStack>
                <Text as="p">
                  <span
                    className="cursor-pointer text-[14px] font-[550]"
                    onClick={item.clickHandle}
                  >
                    {item.title}
                  </span>
                </Text>
                <Text as="p" tone="subdued">
                  <span className="text-[12px]">{item.desc}</span>
                </Text>
              </BlockStack>
            </InlineStack>
          ))}
        </BlockStack>
        <div className="mx-10 hidden h-[168px] w-[1px] bg-[#E3E3E3] md:block"></div>
        <div className="mt-4 flex flex-col gap-4 md:mt-0">
          {needHelpRCard.map((item, index) => (
            <InlineStack key={index} blockAlign="center" gap={'400'}>
              <Box>
                <img className="h-10 w-10" src={item.iconName} alt="avatar" />
              </Box>
              <BlockStack>
                <Text as="p">
                  <span className="text-[14px] font-[550]">{item.title}</span>
                </Text>
                <Text as="p" tone="subdued">
                  <span className="text-[12px]">{item.desc}</span>
                </Text>
              </BlockStack>
            </InlineStack>
          ))}
        </div>
      </div>
    </ModuleCard>
  )
}
