import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/program')({
  loader: () =>
    Promise.all([
      i18n.loadNamespaces('program/common'),
      i18n.loadNamespaces('program/points'),
      i18n.loadNamespaces('program/referral'),
      i18n.loadNamespaces('program/email'),
      i18n.loadNamespaces('constant'),
    ]),
})
