import rewards from '@/api/rewards'
import { COUPON_TYPES } from '@/constants/coupon'
import {
  use0ToBillion,
  use1To60,
  use1To999x,
} from '@/hooks/common/use-number-field'
import { useRequiredField } from '@/hooks/common/use-required-field'
import { navigateBack } from '@/lib/navigate-back'
import ShopifyBridge from '@/lib/shopify-bridge'
import { useField, useForm } from '@shopify/react-form'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'

export const DEFAULT_FREE_SHIPPING_COUPON = {
  amount: 25,
  combines_with: {
    order_discounts: false,
    product_discounts: false,
  },
  coupon_expire_status: 0,
  coupon_expire_time: 6,
  coupon_type: COUPON_TYPES.Shipping,
  default_icon_id: 1,
  icon_url: '',
  is_upload_icon: 0,
  points: 1000,
  required: 1,
  required_mini_amount: 10,
  status: 1,
  is_maximum: 0,
}

export function useFreeShippingForm(
  id: string | number | undefined,
  amountCoupon: typeof DEFAULT_FREE_SHIPPING_COUPON,
) {
  const isEdit = !!id
  const { t } = useTranslation()

  const status = useField(amountCoupon.status)
  const coupon_type = useField(amountCoupon.coupon_type)
  const is_maximum = useField(amountCoupon.is_maximum)

  const points = use1To999x(amountCoupon.points)
  const amount = use0ToBillion(amountCoupon.amount)

  const required = useField(amountCoupon.required)
  const required_mini_amount = use0ToBillion(
    amountCoupon.required_mini_amount,
    required.value === 2,
  )

  const coupon_expire_status = useField(amountCoupon.coupon_expire_status)
  const coupon_expire_time = use1To60(
    amountCoupon.coupon_expire_time,
    coupon_expire_status.value,
  )

  const combines_with = useRequiredField(amountCoupon.combines_with)

  const is_upload_icon = useField(amountCoupon.is_upload_icon)
  const default_icon_id = useField(amountCoupon.default_icon_id)
  const icon_url = useField(amountCoupon.icon_url)

  const form = useForm({
    fields: {
      amount,
      is_maximum,
      combines_with,
      coupon_expire_status,
      coupon_expire_time,
      coupon_type,
      default_icon_id,
      icon_url,
      is_upload_icon,
      points,
      required,
      required_mini_amount,
      status,
    },
    makeCleanAfterSubmit: true,
    onSubmit: async (values) => {
      try {
        if (isEdit) {
          await rewards.rewardsUpdateCoupon({
            id: Number(id),
            ...values,
          })

          ShopifyBridge.toast.show(t('Common.Success.save'))
        } else {
          await rewards.createCoupon(values)

          ShopifyBridge.toast.show(t('Common.Success.save'))
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              navigateBack('program')
            })
          })
        }
        return {
          status: 'success',
        }
      } catch (e: any) {
        return {
          status: 'fail',
          errors: [
            {
              message: e.message,
            },
          ],
        }
      }
    },
  })

  // useLeaveBlocker(form.dirty)
  return form
}

export const FreeShippingFormContext = createContext<
  ReturnType<typeof useFreeShippingForm>
>(null as any)
