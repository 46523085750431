import { createFileRoute } from '@tanstack/react-router'
import EmailApi from '@/api/email'
import { DesignSkeleton } from '@/components/ui/skeleton'
import type { TemplateEmailResponse } from '@/api/types/email'
import i18n from '@/i18n'
export const Route = createFileRoute('/email/birthday-reward')({
  loaderDeps: (param) => param,
  loader: async (param: any) => {
    const [data] = await Promise.all([
      EmailApi.getEmailTemplate({ id: param.deps.search.id }).then(
        (res) => res.data as TemplateEmailResponse,
      ),
      i18n.loadNamespaces('program/email'),
    ])
    return data
  },
  pendingMs: 200,
  pendingComponent: () => <DesignSkeleton />,
  gcTime: 0,
})
