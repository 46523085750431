import axios from 'axios'

const recommendIns = axios.create({
  baseURL: 'https://appapi.sealapps.net/api/v1/',
  method: 'get',
  withCredentials: false,
  headers: { 'Content-Type': 'application/json' },
})

export default recommendIns
