import { createFileRoute } from '@tanstack/react-router'
import EmailApi from '@/api/email'
import { DesignSkeleton } from '@/components/ui/skeleton'
import type { GlobalEmailResponse } from '@/api/types/email'
import i18n from '@/i18n'

export const Route = createFileRoute('/email/design')({
  loader: async () => {
    const [data] = await Promise.all([
      EmailApi.getEmailGlobalSetting().then(
        (res) => res.data as GlobalEmailResponse,
      ),
      i18n.loadNamespaces('program/email'),
    ])
    return data
  },
  pendingMs: 200,
  pendingComponent: () => <DesignSkeleton />,
  gcTime: 0,
})
