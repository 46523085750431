import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/vip-tier/')({
  loader: () =>
    Promise.all([
      i18n.loadNamespaces('program/vip'),
      i18n.loadNamespaces('program/rule'),
      i18n.loadNamespaces('coupon'),
      i18n.loadNamespaces('program/common'),
    ]),
  validateSearch: (params: any) =>
    params as {
      tierId?: string
    },
})

export const VIP_SAVE_BAR_ID = 'vip-tier-save-bar'
