import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'

// MARK: - Route
export const Route = createFileRoute('/program/pop-up')({
  async loader() {
    await i18n.loadNamespaces('program/popup')
    return {}
  },
})
