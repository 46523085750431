import email$ from '@/api/email'
import { SkeletonList } from '@/components/ui/skeleton'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/settings/email')({
  loader: async () => {
    const { data } = await email$.getEmailSetting()
    return data
  },
  pendingMs: 200,
  pendingComponent: () => <SkeletonList i18nKeys={['Email.title']} />,
  gcTime: 0,
})
