import { userPlanInstanceAtom } from '@/atom/shop-info'
import { BENEFIT_KEYS } from '@/lib/plan/benefit-keys'
import { InlineStack, Link, Text } from '@shopify/polaris'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import CountDown from './CountDown'
import type { CountDownType } from './PlanInfo'

export const IsPaidPlan = ({ onClick }: { onClick: () => void }) => {
  const { t: constantT } = useTranslation('constant')
  const { t: billingT } = useTranslation('billing')
  const userPlanInstance = useAtomValue(userPlanInstanceAtom)
  const orderPermission = userPlanInstance.getPermission(
    BENEFIT_KEYS.order_limit,
    userPlanInstance.orderUsageCount,
  )
  const isOrderCountDown = (o: CountDownType) => {
    return Number(Number(o.d) * 24 + Number(o.h)) || Number(o.m) || Number(o.s)
  }
  const countDownTime = (timeObj: CountDownType) => {
    return `${constantT('hours', { count: Number(timeObj.d) * 24 + Number(timeObj.h) })}
    ${constantT('minutes', { count: Number(timeObj.m) })}
    ${constantT('seconds', { count: Number(timeObj.s) })}`
  }
  if (orderPermission) return null
  return (
    <div>
      <CountDown
        time={userPlanInstance.orderExpireTime}
        children={(timeObj: { d: string; h: string; m: string; s: string }) => (
          <div>
            {userPlanInstance.orderExpireTime > 0 &&
              Boolean(isOrderCountDown(timeObj)) && (
                <InlineStack gap={'200'}>
                  <Text as="p">
                    <span className="text-[#FF2200]">
                      {billingT('limitExceededAfter', {
                        countDown: countDownTime(timeObj),
                      })}
                    </span>
                  </Text>
                  <Link onClick={onClick}>{billingT('clickToUpgrade')}</Link>
                </InlineStack>
              )}
            {(userPlanInstance.orderExpireTime <= 0 ||
              !isOrderCountDown(timeObj)) && (
              <InlineStack gap={'200'}>
                <Text as="p">
                  <span className="text-[#FF2200]">
                    {billingT('limitExceeded')}
                  </span>
                </Text>
                <Link onClick={onClick}>{billingT('upgradeToDisplay')}</Link>
              </InlineStack>
            )}
          </div>
        )}
      />
    </div>
  )
}
