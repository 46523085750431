import { BlockStack, Box, Text, InlineStack, Button } from '@shopify/polaris'
import { Trans, useTranslation } from 'react-i18next'
import Anne from '@/assets/images/home/Anne.webp'
import Congratulations from '@/assets/images/home/Congratulations.webp'

export const HomeModal = ({
  open,
  onConfirm,
}: {
  open?: boolean
  onConfirm?: () => void
}) => {
  const { t: homeT } = useTranslation('home')
  if (!open) return null
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-[#00000080]">
      <div className="max-h-[360px] max-w-[512px] rounded-300 py-4">
        <BlockStack align="center" inlineAlign="center">
          <div className="relative">
            <div className="h-[80px] w-full">
              <img
                className="h-[80px] w-full"
                src={Congratulations}
                alt="Congratulations"
              />
            </div>
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-10%]">
              <Text as="p">
                <span className="text-[24px] font-[700] text-white">
                  {homeT('congratulationsModel')}
                </span>
              </Text>
            </div>
          </div>
          <div className="bg-white">
            <Box padding={'400'}>
              <BlockStack gap={'400'} align="center" inlineAlign="center">
                <InlineStack align="center" blockAlign="center" gap={'400'}>
                  <Box>
                    <img className="h-12 w-12" src={Anne} alt="Anne" />
                  </Box>
                  <BlockStack>
                    <Text as="p">
                      <span className="text-[16px] font-[650]">
                        {homeT('anneStreep')}
                      </span>
                    </Text>
                    <Text as="p">{homeT('customerSuccessManager')}</Text>
                  </BlockStack>
                </InlineStack>
                <div className="flex w-full items-center justify-center rounded-300 bg-[#F0F4FF] p-4">
                  <Text as="p" alignment="center">
                    <span className="text-[14px] font-[450]">
                      <Trans
                        ns="home"
                        i18nKey="congratulationsModelContent"
                        values={{
                          value: '72%',
                        }}
                        components={{
                          bold: <span className="text-[24px] font-bold" />,
                        }}
                      />
                    </span>
                  </Text>
                </div>
              </BlockStack>
            </Box>
          </div>
          <div className="flex w-full items-center justify-center rounded-b-300 bg-white pb-4">
            <Button variant="primary" onClick={onConfirm}>
              {homeT('started')}
            </Button>
          </div>
        </BlockStack>
      </div>
    </div>
  )
}
