import referral from '@/api/referral'
import type { Collection } from '@/components/modules/rule-components/type/order'
import { REFERRAL_REWARD_TYPES } from '@/constants/coupon'
import { use0ToBillion, use1To60 } from '@/hooks/common/use-number-field'
import {
  useCollectionField,
  useNotFalsyField,
  useRequiredField,
} from '@/hooks/common/use-required-field'
import { navigateBack } from '@/lib/navigate-back'
import ShopifyBridge from '@/lib/shopify-bridge'
import { useField, useForm } from '@shopify/react-form'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'

export const DEFAULT_REFERRAL_AMOUNT_COUPON = {
  coupon_type: REFERRAL_REWARD_TYPES.Amount,
  amount: 5,
  is_mini_amount: 0,
  required_mini_amount: 10,
  product_range: 1,
  product_rid: 0,
  is_maximum: 0,
  expire_status: 0,
  expire_time: 6,
  default_icon_id: 1,
  is_upload_icon: 0,
  icon_url: '',
  file_name: '',
  variant_id: [],
  combines_with: {
    order_discounts: false,
    product_discounts: false,
    shipping_discounts: false,
  },
  title: '',
  sku_name: '',
  image_src: '',
  select_collection: [] as Collection[],
  collection_id: [] as number[],
}

export function useReferralFields(
  data: typeof DEFAULT_REFERRAL_AMOUNT_COUPON,
  referral_type: 1 | 2,
) {
  const { t } = useTranslation()

  let defaultAmount = data.amount
  // percentage
  if (data.coupon_type === REFERRAL_REWARD_TYPES.Percentage) {
    defaultAmount = defaultAmount * 100
  }

  // shipping
  const is_maximum = useField(data.is_maximum)

  const amount = use0ToBillion(
    defaultAmount,
    data.coupon_type === REFERRAL_REWARD_TYPES.Shipping
      ? is_maximum.value
      : true,
  )

  const is_mini_amount = useField(data.is_mini_amount)
  const required_mini_amount = use0ToBillion(
    data.required_mini_amount,
    is_mini_amount.value,
  )

  const product_range = useField(data.product_range)

  if (data.select_collection.length) {
    data.collection_id = data.select_collection.map(
      (collection) => collection.collection_rid,
    )
  }
  const collection_id = useCollectionField(
    data.collection_id,
    product_range.value,
  )
  const product_rid = useNotFalsyField(
    data.product_rid,
    t('coupon:freeProduct'),
    product_range.value === 3,
  )
  const select_collection = useField(data.select_collection ?? [])
  const variant_id = useField(data.variant_id)

  const combines_with = useRequiredField(data.combines_with)

  const expire_status = useField(data.expire_status)
  const expire_time = use1To60(data.expire_time, expire_status.value)

  const is_upload_icon = useField(data.is_upload_icon)
  const default_icon_id = useField(data.default_icon_id)
  const icon_url = useField(data.icon_url)

  const fields = {
    amount,
    is_maximum,
    collection_id,
    variant_id,
    product_rid,
    combines_with,
    default_icon_id,
    icon_url,
    is_upload_icon,
    product_range,
    is_mini_amount,
    required_mini_amount,

    // ignore this...
    select_collection,
    title: useField(data.title ?? ''),
    sku_name: useField(data.sku_name ?? ''),
    image_src: useField(data.image_src ?? ''),
  }

  if (referral_type === 1) {
    return {
      ...fields,
      expire_status,
      expire_time,
    }
  } else {
    return fields
  }
}

export function useReferralCouponFom(
  referral_type: 1 | 2,
  isEdit: boolean,
  couponData: typeof DEFAULT_REFERRAL_AMOUNT_COUPON,
  submitIncludeKeys: string[] | null = null,
) {
  const { t } = useTranslation()
  const fields = useReferralFields(couponData, referral_type)
  const form = useForm({
    fields,
    makeCleanAfterSubmit: true,
    onSubmit: async ({
      select_collection,
      title,
      sku_name,
      image_src,
      ...values
    }) => {
      try {
        if (couponData.coupon_type === REFERRAL_REWARD_TYPES.Percentage) {
          values.amount = values.amount / 100
        }

        // handle submitIncludeKeys
        if (submitIncludeKeys) {
          const submitValues: any = {}
          for (const key of submitIncludeKeys) {
            submitValues[key] = (values as Record<string, any>)[key]
          }
          values = submitValues
        }

        await referral.saveReferralReward({
          ...values,
          coupon_type: couponData.coupon_type,
          referral_type: referral_type,
        })
        ShopifyBridge.toast.show(t('Common.Success.save'))
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            navigateBack('program/referrals')
          })
        })

        return {
          status: 'success',
        }
      } catch (e: any) {
        return {
          status: 'fail',
          errors: [
            {
              message: e.message,
            },
          ],
        }
      }
    },
  })

  // useLeaveBlocker(form.dirty)

  return form
}

export const ReferralCouponFormContext = createContext<
  ReturnType<typeof useReferralCouponFom>
>(null as any)
