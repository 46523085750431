import { REFERRAL_REWARD_TYPES } from '@/constants/coupon'
import { composeReferralCouponRouteTemplate } from '@/lib/coupon/gen-code'
import { DEFAULT_REFERRAL_AMOUNT_COUPON } from '@/providers/coupon/ReferralCouponProvider'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/referral-coupon/free-shipping')(
  composeReferralCouponRouteTemplate({
    ...DEFAULT_REFERRAL_AMOUNT_COUPON,
    combines_with: {
      order_discounts: false,
      product_discounts: false,
    },
    coupon_type: REFERRAL_REWARD_TYPES.Shipping,
    amount: '10.00',
  }),
)
