export const enum EmitKeyMap {
  EditDataUpdate = 'editDataUpdate',

  Publish = 'Publish',
  Refetch = 'Refetch',
}

export const PLAN_NAMES = {
  Free: 'Free',
  FreeEasterEgg: 'Free easter egg',
  FreeUnlocked: 'Free unlocked',
  Starter: 'Starter',
  Growth: 'Growth',
  Plus: 'Plus',
}

// 订单无限替换值
export const UNLIMITED_ORDER_COUNT = 99999999

export const INTERVALS = {
  every_30_days: 'every_30_days',
  EVERY_30_DAYS: 'EVERY_30_DAYS',
  annual: 'annual',
  ANNUAL: 'ANNUAL',
}

// 店铺创建时间是否早于上线时间  1681209000 2023-04-11 18:30:00
export const SHOP_ONLINE_TIME = 1681209000

// 调起willdesk客服弹窗预置文案
export const WILLDESK_PRESET_CONTENT = {
  unlock:
    "👋Hi.  I'd like to unlock the experience of the Loloyal loyalty program (worth $29 per month) 😀.",
}
// 积分规则的rule_id
export const POINT_RULE_ID = {
  review: 1,
  order: 2,
  signUp: 3,
  birthday: 4,
  subscribe: 5,
  facebook: 6,
  twitter: 7,
  instagram: 8,
  tikTok: 9,
  visitWebsite: 10,
  youtube: 11,
}
