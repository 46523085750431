import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/language/')({
  beforeLoad: () =>
    Promise.all([
      i18n.loadNamespaces('brand-style/panel'),
      i18n.loadNamespaces('brand-style/translate'),
    ]),
})
