import {
  filtersAtom,
  keepHistoryAtom,
  searchCustomerKeywordAtom,
  searchCustomerPageAtom,
  searchCustomerSortAtom,
} from '@/atom/modules/customers'
import { createFileRoute } from '@tanstack/react-router'
import { getDefaultStore } from 'jotai'
import { z } from 'zod'

const customerSearchSchema = z.object({
  customerRid: z.number().optional(),
  f: z.enum(['settings']).optional(),
})

export const Route = createFileRoute('/customers/')({
  validateSearch: customerSearchSchema,
  beforeLoad() {
    const store = getDefaultStore()
    const keepHistory = store.get(keepHistoryAtom)
    if (keepHistory) {
      store.set(keepHistoryAtom, false)
    } else {
      store.set(searchCustomerKeywordAtom, '')
      store.set(searchCustomerPageAtom, 1)
      store.set(searchCustomerSortAtom, 'newest_first')
      store.set(filtersAtom, [])
    }
    return true
  },
})
