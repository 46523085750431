export function addThousandSeparator(value?: number | string) {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return ''
  }
  const str = value.toString()
  let intNumber
  let floatNumber
  if (str.includes('.')) {
    intNumber = str.substring(0, str.indexOf('.'))
    floatNumber = str.substring(str.indexOf('.'))
    intNumber = intNumber.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    const result = intNumber + floatNumber
    return result
  } else {
    const result = str.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    return result
  }
}

if (import.meta.vitest) {
  const { test, expect } = import.meta.vitest
  test('addThousandSeparator', () => {
    expect(addThousandSeparator(123456789)).toBe('123,456,789')
    expect(addThousandSeparator(123456789.123)).toBe('123,456,789.123')
    expect(addThousandSeparator('123456789')).toBe('123,456,789')
    expect(addThousandSeparator('123456789.123')).toBe('123,456,789.123')
  })
}

export function currencyFormat(
  value: number | string,
  throwFractionDigits?: boolean,
) {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  if (Number.isNaN(value)) {
    return ''
  }

  if (throwFractionDigits && Number.isInteger(value)) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
