/**
 * 优惠券类型
 */

import { BENEFIT_KEYS } from '@/lib/plan/benefit-keys'

export enum COUPON_TYPES {
  Fixed = 1,
  Percentage = 2,
  Shipping = 3,
  Increments = 4,
  Product = 5,
  PosAmount = 6,
  PosPercentage = 7,
}

export enum REFERRAL_REWARD_TYPES {
  Amount = 1,
  Percentage = 2,
  Points = 3,
  Shipping = 4,
  Product = 5,
}

export const PLAN_COUPONS = [
  COUPON_TYPES.Shipping,
  COUPON_TYPES.Product,
  COUPON_TYPES.PosAmount,
  COUPON_TYPES.PosPercentage,
]

export const COUPON_BENEFIT_KEYS = {
  [COUPON_TYPES.Product]: BENEFIT_KEYS.free_product_coupon,
  [COUPON_TYPES.Shipping]: BENEFIT_KEYS.free_shipping_coupon,
  [COUPON_TYPES.PosAmount]: BENEFIT_KEYS.shopify_pos,
  [COUPON_TYPES.PosPercentage]: BENEFIT_KEYS.shopify_pos,
}
