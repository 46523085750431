import { BlockStack, Box, InlineStack, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export const BrandingFooter = () => {
  const { t } = useTranslation()

  return (
    <Box paddingBlock="1000">
      <BlockStack inlineAlign="center">
        <InlineStack gap={{ xs: '400', md: '800', lg: '1200' }}>
          <BlockStack gap="100">
            <InlineStack gap="200" blockAlign="center">
              <img
                className="size-6"
                src="/modules/official/LoloyalLogo.svg"
                alt="logo"
              />
              <Text as="p" variant="headingXs">
                <span className="text-loloyal-default">
                  {t('Common.Branding.appName')}
                </span>
              </Text>
            </InlineStack>
            <Box>
              <Text as="p" variant="bodyXs">
                <span className="text-loloyal-subdued">
                  {t('Common.Branding.over25000Brands')}
                </span>
              </Text>
              <Text as="p" variant="bodyXs">
                <span className="text-loloyal-subdued">
                  {t('Common.Branding.serveMoreCustomers')}
                </span>
              </Text>
            </Box>
          </BlockStack>
          <InlineStack gap="500">
            <img
              className="w-[72px]"
              src="/modules/footer/Spring2024.svg"
              alt="footerIcon"
              width={72}
              height={72}
            />
            <img
              className="w-[72px]"
              src="/modules/footer/Summary2024.svg"
              alt="footerIcon"
              width={72}
              height={72}
            />
            <img
              className="w-[72px]"
              src="/modules/footer/Popular2023.svg"
              alt="footerIcon"
              width={72}
              height={72}
            />
          </InlineStack>
        </InlineStack>
      </BlockStack>
    </Box>
  )
}
