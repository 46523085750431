import '@/i18n'
import auth from '@/lib/auth/oAuth'
import '@/styles/global.css'
import '@shopify/polaris/build/esm/styles.css'
import { QueryClient } from '@tanstack/react-query'
import { createRouter } from '@tanstack/react-router'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { routeTree } from './routeTree.gen'

export const router = createRouter({ routeTree })
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export const queryClient = new QueryClient()

//  区分 app-bridge Modal 的环境
if (window.shopify && 'ready' in window.shopify) {
  auth.init()

  const rootElement = document.getElementById('root')!
  if (!rootElement?.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
      <StrictMode>
        <App />
      </StrictMode>,
    )
  }
}
