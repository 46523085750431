import eventMarketing from '@/api/eventMarketing'
import i18n from '@/i18n'
import type { YMD } from '@/types/date'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { atom } from 'jotai'

export const new_event_coupons_atom = atom<null | any[]>(null)

export const stash_data_atom = atom({
  events_start_ymd: null as null | YMD, //  作为优惠券的默认值
  events_end_ymd: null as null | YMD, //  作为优惠券的默认值
})
export const Route = createFileRoute('/marketing-events/$id')({
  beforeLoad() {
    i18n.loadNamespaces('program/limited-time-offers')
  },
})

export function useEventById(id: number, isEdit?: boolean) {
  return useQuery({
    queryKey: ['selEventsById', id],
    queryFn: () =>
      eventMarketing
        .selEventsById({ id: isEdit ? Number(id) : 0 })
        .then((resp) => resp.data),
    refetchOnWindowFocus: false,

    gcTime: 0,
    enabled: isEdit,
  })
}
