import ThemeFreeCart from '@/assets/images/Billing/ThemeFreeCart.png'
import ThemePlusPlan from '@/assets/images/Billing/ThemePlusPlan.png'
import ThemeRocket from '@/assets/images/Billing/ThemeRocket.png'
import ThemeStarterPlane from '@/assets/images/Billing/ThemeStarterPlane.png'
import { userPlanInstanceAtom } from '@/atom/shop-info'
import { IsPaidPlan } from '@/components/modules/billing/IsPaidPlan'
import { NotPaidPlan } from '@/components/modules/billing/NotPaidPlan'
import { ModuleCard } from '@/components/ui/card/ModuleCard'
import { BENEFIT_KEYS } from '@/lib/plan/benefit-keys'
import { BlockStack, Box, InlineStack, Text } from '@shopify/polaris'
import { useNavigate } from '@tanstack/react-router'
import { useAtomValue } from 'jotai'
import { toLower } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { FakeButton } from '../billing/FakeButton'

export const CurrentPlan = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { t: homeT } = useTranslation('home')
  const userPlanInstance = useAtomValue(userPlanInstanceAtom)
  const orderBenefitValue = userPlanInstance.getBenefitValue(
    BENEFIT_KEYS.order_limit,
  )
  const computedPlanIcon = () => {
    if (userPlanInstance.isLTStarter) return ThemeFreeCart
    else if (userPlanInstance.isStarter) return ThemeStarterPlane
    else if (userPlanInstance.isGrowth) return ThemeRocket
    else if (userPlanInstance.isPlus) return ThemePlusPlan
  }
  let orderIsOverLimit = false
  if (typeof orderBenefitValue === 'number' && orderBenefitValue !== -1) {
    if (userPlanInstance?.orderUsageCount > orderBenefitValue) {
      // 超过订单限制
      orderIsOverLimit = true
    }
  }
  return (
    <ModuleCard title={homeT('currentPlan')}>
      <InlineStack blockAlign="center" align="space-between">
        <InlineStack blockAlign="center" gap={'400'}>
          <Box>
            <img className="h-10 w-10" src={computedPlanIcon()} alt="plan" />
          </Box>
          <BlockStack>
            <Text as="p">
              <span className="text-[16px] font-bold">
                {t(`Common.Plan.${toLower(userPlanInstance.displayName)}`)}
              </span>
            </Text>
            <Text as="p" tone="subdued">
              <Trans
                ns="home"
                i18nKey="monthlyOrderUsage"
                values={{
                  order: userPlanInstance.orderUsageCount,
                  limit:
                    orderBenefitValue === -1
                      ? homeT('unlimited')
                      : orderBenefitValue,
                }}
                components={{
                  strong: (
                    <span
                      className={`font-bold ${orderIsOverLimit ? 'text-[#FF2200]' : 'text-loloyal-default'}`}
                    />
                  ),
                  bold: <span className="font-bold text-loloyal-default" />,
                }}
              />
            </Text>
            {userPlanInstance.isPaidPlan ? (
              <IsPaidPlan
                onClick={() => {
                  navigate({ to: '/billing' })
                }}
              ></IsPaidPlan>
            ) : (
              <NotPaidPlan
                onClick={() => {
                  navigate({ to: '/billing' })
                }}
              ></NotPaidPlan>
            )}
          </BlockStack>
        </InlineStack>
        <FakeButton
          className="bg-loloyal-primary"
          onClick={() => {
            navigate({ to: '/billing' })
          }}
        >
          <Text as="p">
            <span className="text-[12px] text-white">
              {userPlanInstance.isLTStarter
                ? t('Common.Button.upgrade')
                : t('Common.Button.view')}
            </span>
          </Text>
        </FakeButton>
      </InlineStack>
    </ModuleCard>
  )
}
