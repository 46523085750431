import rewards from '@/api/rewards'
import type { Collection } from '@/components/modules/rule-components/type/order'
import { COUPON_TYPES } from '@/constants/coupon'
import {
  use0ToBillion,
  use1To60,
  use1To999x,
} from '@/hooks/common/use-number-field'
import { useCollectionField } from '@/hooks/common/use-required-field'
import { navigateBack } from '@/lib/navigate-back'
import ShopifyBridge from '@/lib/shopify-bridge'
import predicates from '@/lib/validation/predicates'
import { requiredBy } from '@/lib/validation/requiredBy'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { produce } from 'immer'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'

export const DEFAULT_AMOUNT_COUPON = {
  amount: 5,
  collection_id: [] as number[],
  combines_with: {
    order_discounts: false,
    product_discounts: false,
    shipping_discounts: false,
  },
  coupon_expire_status: 0,
  coupon_expire_time: 6,
  coupon_type: COUPON_TYPES.Fixed,
  default_icon_id: 1,
  icon_url: '',
  is_upload_icon: 0,
  max_points: 1000,
  mini_points: 100,
  points: 500,
  product_range: 1,
  required: 1,
  required_mini_amount: 10,
  required_max_points: 0,
  required_mini_points: 0,
  status: 1,
  inPoints: 100,
  inAmount: 1,
  select_collection: [] as Collection[],
}

function prepareSubmitData(data: Record<string, any>) {
  const { inAmount, inPoints, ...submitData } = produce(data, (draft) => {
    const isFixed = draft.coupon_type === COUPON_TYPES.Fixed
    draft.amount = isFixed ? draft.amount : draft.inAmount
    draft.points = isFixed ? draft.points : draft.inPoints
  })
  return submitData
}

export function useAmountFields(data: typeof DEFAULT_AMOUNT_COUPON) {
  const status = useField(data.status)
  const coupon_type = useField(data.coupon_type)

  const isFixed = coupon_type.value === COUPON_TYPES.Fixed
  const isIncrements = coupon_type.value === COUPON_TYPES.Increments

  const amount = use0ToBillion(
    isFixed ? data.amount : DEFAULT_AMOUNT_COUPON['amount'],
    isFixed,
  )
  const inAmount = use0ToBillion(
    isIncrements ? data.amount : DEFAULT_AMOUNT_COUPON['inAmount'],
    isIncrements,
  )
  const points = use1To999x(
    isFixed ? data.points : DEFAULT_AMOUNT_COUPON['points'],
    isFixed,
  )
  const inPoints = use1To999x(
    isIncrements ? data.points : DEFAULT_AMOUNT_COUPON['inPoints'],
    isIncrements,
  )

  const { t } = useTranslation()

  const required_max_points = useField(data.required_max_points)
  const required_mini_points = useField(data.required_mini_points)
  const mini_points = use0ToBillion(data.mini_points, data.required_mini_points)
  const max_points = useField(
    {
      value: data.max_points,
      validates: [
        requiredBy(
          !!required_max_points.value,
          notEmpty(t('Common.Error.mustHaveQuantity')),
        ),
        requiredBy(
          !!required_max_points.value,
          predicates.checkMax(100000000, false),
        ),
        requiredBy(!!required_max_points.value, predicates.checkMini(0, false)),
        requiredBy(
          !!required_max_points.value && !!required_mini_points.value,
          predicates.checkMini(mini_points.value, false),
        ),
      ],
    },
    [mini_points.value, required_max_points.value, required_mini_points.value],
  )

  const required = useField(data.required)
  const required_mini_amount = use0ToBillion(
    data.required_mini_amount,
    required.value === 2,
  )

  const product_range = useField(data.product_range)
  const select_collection = useField(data.select_collection ?? [])

  if (data.select_collection.length) {
    data.collection_id = data.select_collection.map(
      (collection) => collection.collection_rid,
    )
  }
  const collection_id = useCollectionField(
    data.collection_id ?? [],
    product_range.value,
  )

  const combines_with = useField(data.combines_with)

  const coupon_expire_status = useField(data.coupon_expire_status)
  const coupon_expire_time = use1To60(
    data.coupon_expire_time,
    coupon_expire_status.value,
  )

  const is_upload_icon = useField(data.is_upload_icon)
  const default_icon_id = useField(data.default_icon_id)
  const icon_url = useField(data.icon_url)

  return {
    amount,
    collection_id,
    select_collection,
    combines_with,
    coupon_expire_status,
    coupon_expire_time,
    coupon_type,
    default_icon_id,
    icon_url,
    is_upload_icon,
    max_points,
    mini_points,
    points,
    product_range,
    required,
    required_max_points,
    required_mini_amount,
    required_mini_points,
    status,
    inPoints,
    inAmount,
  }
}

export function useAmountForm(
  id: string | number | undefined,
  amountCoupon: typeof DEFAULT_AMOUNT_COUPON,
) {
  const isEdit = !!id
  const { t } = useTranslation()
  const fields = useAmountFields(amountCoupon)
  const form = useForm({
    fields,
    makeCleanAfterSubmit: true,
    onSubmit: async ({ select_collection, ...values }) => {
      try {
        if (isEdit) {
          const submitData = prepareSubmitData(values)
          await rewards.rewardsUpdateCoupon({
            id: Number(id),
            ...submitData,
          })

          ShopifyBridge.toast.show(t('Common.Success.save'))
        } else {
          const submitData = prepareSubmitData(values)
          await rewards.createCoupon(submitData)

          ShopifyBridge.toast.show(t('Common.Success.save'))
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              navigateBack('program')
            })
          })
        }
        return {
          status: 'success',
        }
      } catch (e: any) {
        return {
          status: 'fail',
          errors: [
            {
              message: e.message,
            },
          ],
        }
      }
    },
  })

  // useLeaveBlocker(form.dirty)

  return form
}

export const AmountFormContext = createContext<
  ReturnType<typeof useAmountForm>
>(null as any)
