import {
  BlockStack,
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
} from '@shopify/polaris'

export const DetailPageSkeleton = () => {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            {new Array(2).fill(0).map((_, index) => (
              <Card key={index}>
                <BlockStack gap="200">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={12} />
                </BlockStack>
              </Card>
            ))}
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Card>
            <BlockStack gap="200">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={12} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
