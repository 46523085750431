import i18n from '@/i18n'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/brand-style')({
  loader: () =>
    Promise.all([
      i18n.loadNamespaces('brand-style/panel'),
      i18n.loadNamespaces('brand-style/translate'),
      i18n.loadNamespaces('brand-style/tool'),
    ]),
})
