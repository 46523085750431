import { COUPON_TYPES } from '@/constants/coupon'
import { DEFAULT_POS_AMOUNT_COUPON } from '@/providers/coupon/PosAmountProvider'
import _ from 'lodash'

const posAmountCoupon = _.cloneDeep(DEFAULT_POS_AMOUNT_COUPON)

export const DEFAULT_POS_PERCENTAGE_COUPON = {
  ...posAmountCoupon,
  coupon_type: COUPON_TYPES.PosPercentage,
  amount: 0.1,
}
