import FiveStarEmo from '@/assets/images/emoji/FiveStarEmo.webp'
import FourStarEmo from '@/assets/images/emoji/FourStarEmo.webp'
import NoStarEmo from '@/assets/images/emoji/NoStarEmo.webp'
import OneStarEmo from '@/assets/images/emoji/OneStarEmo.webp'
import ThreeStarEmo from '@/assets/images/emoji/ThreeStarEmo.webp'
import TwoStarEmo from '@/assets/images/emoji/TwoStarEmo.webp'
import { PopoverCloseAction } from '@/components/common/popover/PopoverCloseAction'
import storageScheme from '@/lib/storage-scheme'
import { Card, Divider, InlineStack, Text } from '@shopify/polaris'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './CommentBanner.css'

const sessionKey = 'CloseHomeReviewBanner'

export const CommentBanner = () => {
  const { t } = useTranslation()
  const [icon, setIcon] = useState(NoStarEmo)
  const [show, setShow] = useState(
    !storageScheme.getItem(sessionKey, 'session'),
  )
  const [description, setDescription] = useState(
    t('Common.Review.leaveYourFeedback'),
  )

  // 文案索引与五星顺序相反
  const reviewDescArr = [
    {
      icon: FiveStarEmo,
      i18nKey: 'clickToRate',
    },
    {
      icon: FourStarEmo,
      i18nKey: 'howToGetStars',
    },
    {
      icon: ThreeStarEmo,
      i18nKey: 'hearWhy',
    },
    {
      icon: TwoStarEmo,
      i18nKey: 'howToImprove',
    },
    {
      icon: OneStarEmo,
      i18nKey: 'whatWrong',
    },
  ]

  const close = () => {
    setShow(false)
    storageScheme.setItem(sessionKey, 'true', 'session')
  }

  const handleClick = (index: number) => {
    close()
    if (index === 0) {
      window.open(
        'https://apps.shopify.com/loloyal?surface_detail=lolyalty&surface_inter_position=1&surface_intra_position=15&surface_type=search',
      )
    } else if (index > 0) {
      window.Willdesk?.chat()
    }
  }

  const handleMouseover = (index: number) => {
    setIcon(reviewDescArr[index].icon)
    setDescription(t(`Common.Review.${reviewDescArr[index].i18nKey}`))
  }

  const handleMouseout = () => {
    setIcon(NoStarEmo)
    setDescription(t('Common.Review.leaveYourFeedback'))
  }

  return (
    <>
      {show ? (
        <Card>
          <InlineStack align="space-between" blockAlign="center">
            <InlineStack wrap={false} gap="400" blockAlign="center">
              <img
                src="/modules/avatar/Lily.webp"
                alt="avatar"
                className="size-14"
              />
              <Text as="p" variant="bodyLg" fontWeight="medium">
                {t('Common.Review.statusCheck')}
              </Text>
              <InlineStack gap="400" align="start">
                <InlineStack gap="100" align="center" direction="row-reverse">
                  {new Array(5).fill(0).map((_, index) => {
                    return (
                      <input
                        key={index}
                        type="radio"
                        name="rate"
                        aria-label="star"
                        value={1}
                        aria-labelledby="rate"
                        onClick={() => {
                          handleClick(index)
                        }}
                        onMouseOver={() => {
                          handleMouseover(index)
                        }}
                        onMouseOut={handleMouseout}
                      />
                    )
                  })}
                </InlineStack>
                <InlineStack gap="200">
                  <img src={icon} alt="emoji" width={30} height={30} />
                  <Text as="p" variant="bodyLg" fontWeight="medium">
                    {description}
                  </Text>
                </InlineStack>
              </InlineStack>
            </InlineStack>
            <PopoverCloseAction onClick={close} />
          </InlineStack>
        </Card>
      ) : (
        <Divider borderColor="border" />
      )}
    </>
  )
}
