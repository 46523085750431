import recommendIns from '@/lib/recommend-app-request'

export interface RecommendDataType {
  id: string
  title: string
  image: string
  jump_link: string
  detail: string
  plan: string
}

export default {
  // 获取广告服务的app推荐数据
  getRecommendData(params = { appId: 4 }) {
    return recommendIns.get<RecommendDataType[]>(
      'getDateShow?appId=' + params.appId,
    )
  },
}
