import { PopoverAction } from '@/components/ui/popover/PopoverAction'
import { Button } from '@shopify/polaris'
import { XIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

export const PopoverCloseAction = (props: { onClick?: () => void }) => {
  const { t } = useTranslation()

  return (
    <PopoverAction>
      <div className="mt-[5px]">
        <Button
          onClick={props.onClick}
          icon={XIcon}
          variant="plain"
          tone="critical"
        >
          {t('Common.Button.close')}
        </Button>
      </div>
    </PopoverAction>
  )
}
