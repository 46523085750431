import type { PlanUserBenefit } from '@/atom/shop-info'
import { Benefit, OrderLimitBenefit } from './Class'
import { BENEFIT_KEYS } from './benefit-keys'

const EXTENDS_BENEFITS = [
  {
    benefit: OrderLimitBenefit,
    benefitKey: BENEFIT_KEYS.order_limit,
  },
]

const matchExtendsBenefit = (benefitKey: string) =>
  EXTENDS_BENEFITS.find((item) => item.benefitKey === benefitKey)

// 创建实例
export const createBenefitInstances = (benefits: PlanUserBenefit) => {
  return Object.keys(benefits).map((benefitKey) => {
    const benefit = {
      benefitKey, // key
      ...benefits[benefitKey], // value & apply
    }
    const MatchBenefit = matchExtendsBenefit(benefitKey)?.benefit || Benefit
    return new MatchBenefit(benefit)
  })
}
