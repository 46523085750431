import { shopInfoAtom } from '@/atom/shop-info'
import { PageProgress } from '@/components/layout/root/PageProgress'
import { Sidebar } from '@/components/layout/sidebar'
import { InitWelcome } from '@/components/ui/welcome'
import { useAuth } from '@/hooks/auth/use-auth'
import i18n from '@/i18n'
import { isGuidancePaths, shouldRedirectToGuidance } from '@/lib/auth/guest'
import { hasPadBottom } from '@/lib/padding-handler'
import { pollingPlan } from '@/lib/plan/polling-plan'
import storageScheme from '@/lib/storage-scheme'
import { willDeskHandler, init as willdeskInit } from '@/lib/willdesk'
import {
  Navigate,
  Outlet,
  ScrollRestoration,
  createRootRoute,
  redirect,
  useRouterState,
} from '@tanstack/react-router'
import { getDefaultStore } from 'jotai'
import { useEffect } from 'react'

export const Route = createRootRoute({
  component: Root,
  beforeLoad: ({ location }) => {
    if (shouldRedirectToGuidance(location.href)) {
      throw redirect({
        to: '/guidance',
      })
    }
  },
})

function Root() {
  return (
    <>
      <PageProgress />
      <Sidebar></Sidebar>
      <ScrollRestoration />
      <AuthProvider>
        <GuidanceOrHomeProvider>
          <PaddingBlockEndProvider>
            <Outlet></Outlet>
          </PaddingBlockEndProvider>
        </GuidanceOrHomeProvider>
      </AuthProvider>
    </>
  )
}

function freshLocalI18n() {
  const store = getDefaultStore()
  // i18n refresh
  const shopInfo = store.get(shopInfoAtom)
  if (shopInfo && i18n.language !== shopInfo?.userInfo.language) {
    i18n.changeLanguage(shopInfo?.userInfo.language)
    storageScheme.setItem(
      'loloyal-language',
      shopInfo?.userInfo.language || 'en',
    )
  }
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      const timer = setTimeout(() => {
        willdeskInit(() => {
          willDeskHandler()
        })
      }, 1000)
      freshLocalI18n()
      pollingPlan()
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return <InitWelcome />
  } else {
    return children
  }
}

function GuidanceOrHomeProvider({ children }: { children: React.ReactNode }) {
  const { pathname, pendingMatches } = useRouterState({
    select: (state) => {
      return {
        pathname: state.location.pathname,
        pendingMatches: state.pendingMatches,
      }
    },
  })

  if (shouldRedirectToGuidance(pathname)) {
    return <Navigate replace to="/guidance/" />
  }
  if (
    pendingMatches?.length &&
    pendingMatches.find((r) => isGuidancePaths(r.pathname))
  ) {
    // 加载引导页中, 这段逻辑是为了避免加载引导页时, 闪现加载中状态和缓存的页面
    return null
  } else {
    window.hideLoadingState()
  }
  return children
}

function PaddingBlockEndProvider({ children }: { children: React.ReactNode }) {
  const { pathname } = useRouterState({
    select: (state) => {
      return {
        pathname: state.location.pathname,
      }
    },
  })
  const hasPadding = hasPadBottom(pathname)

  return <div className={hasPadding ? 'pb-6' : 'pb-0'}>{children}</div>
}
