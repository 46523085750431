import themeTemplateApi from '@/api/themeTemplate'
import type { ThemeDataType } from '@/api/types/themeType'
import { DesignSkeleton } from '@/components/ui/skeleton'
import { BlockStack, Box, Card, SkeletonBodyText } from '@shopify/polaris'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/brand-style/translation')({
  loader: () =>
    themeTemplateApi
      .selThemeTemplate()
      .then((res: { data: ThemeDataType }) => res.data),
  pendingComponent: () => <Skeleton />,
  gcTime: 0,
})

export function Skeleton() {
  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="top-4 w-full lg:sticky lg:flex-[0_0_240px]">
        <Card>
          <BlockStack gap="200">
            <SkeletonBodyText lines={8} />
          </BlockStack>
        </Card>
      </div>
      <Box width="100%">
        <DesignSkeleton />
      </Box>
    </div>
  )
}
