import client from '@/lib/request'
import type {
  TTResponse,
  WillDeskResponse,
  SearchResponse,
  AppDetailResponse,
  KlaviyoSyncResponse,
} from './types/integration'

export default {
  // 查询app列表数据（包含连接状态，除去内部集成app）
  searchIntegrated() {
    return client.post<SearchResponse>('integrations/searchIntegrated')
  },
  // 查询 willdesk 与 loloyal 连接状态
  getWillDeskConnectStatus() {
    return client.post<WillDeskResponse>('integrations/willdesk/connect')
  },
  // 查询vstar与loloyal连接状态
  getTTConnectStatus() {
    return client.post<TTResponse>('vstar/connectInfo')
  },
  // judge.me集成连接
  judgeMeConnect(param: { judgemeToken: string }) {
    return client.post('judgeme/connect', param)
  },
  // judge集成断开连接
  judgeMeDisconnect() {
    return client.post('judgeme/disconnect')
  },
  // klaviyo集成连接
  klaviyoConnect(param: { private_token: string }) {
    return client.post('klaviyo/connect', param)
  },
  // klaviyo集成断开连接
  klaviyoDisconnect() {
    return client.post('klaviyo/disconnect')
  },
  // klaviyo集成同步接口
  klaviyoSyncData() {
    return client.post('klaviyo/syncData')
  },
  // klaviyo集成轮询同步状态接口
  getKlaviyoSyncStatus() {
    return client.post<KlaviyoSyncResponse>('klaviyo/getSyncStatus')
  },
  // klaviyo集成更改同步状态（关闭）
  updKlaSyncStatus(param: { process_status: number }) {
    return client.post('klaviyo/updSyncStatus', param)
  },
  // 取消klaviyo同步
  stopSync(param = {}) {
    return client.post('klaviyo/stopSync', param)
  },
  // 获取 app 信息
  getIntegratedApp(param: { id: number }) {
    return client.post<AppDetailResponse>(
      'integrations/getIntegratedApp',
      param,
    )
  },
}
