import client from '../lib/request'
import type { SearchReferralResponse } from './types/referral'

export default {
  // 初始化接口
  searchSettingInfo() {
    return client.post<SearchReferralResponse>('searchSettingInfo')
  },
  // 查询邀请奖励信息（邀请者和被邀请者
  searchReferralReward(param = {}) {
    return client.post('searchReferralReward', param)
  },
  // 保存奖励（邀请者和被邀请者）
  saveReferralReward(param = {}) {
    return client.post('saveReferralReward', param)
  },
  // 删除奖励
  delReferralReward(param = {}) {
    return client.post('delReferralReward', param)
  },
  // 保存准发渠道数据
  saveChannelMassage(param = {}) {
    return client.post('saveChannelMassage', param)
  },
  // 开关状态更改
  updReferralStatus(param: { referral_status: number }) {
    return client.post('updReferralStatus', param)
  },
  // 查询商品
  productFind(param = {}) {
    return client.post('productFind', param)
  },
}
