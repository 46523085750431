import integration$ from '@/api/integration'
import { DetailPageSkeleton } from '@/components/ui/skeleton'
import i18n from '@/i18n'
import { INTEGRATION_APP_IDS } from '@/types/integration'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/integration/klaviyo')({
  loader: async () => {
    const [{ data }] = await Promise.all([
      integration$.getIntegratedApp({
        id: INTEGRATION_APP_IDS.Klaviyo,
      }),
      i18n.loadNamespaces('integration'),
    ])

    return data
  },
  pendingMs: 200,
  pendingComponent: () => <DetailPageSkeleton />,
  gcTime: 0,
})
