export const hasPadBottom = (href: string) => {
  const excludePathStart = ['/guidance', '/program', '/billing']
  const excludePathFull = ['/']
  const includePathFull = ['/guidance/billing']

  const h = href ?? location.href
  const hasMarginBottom =
    (!excludePathStart.some((item) => h.startsWith(item)) &&
      !excludePathFull.some((item) => item === h)) ||
    includePathFull.some((item) => item === h)

  return hasMarginBottom
}
