import tools$ from '@/api/tools'
import { DesignSkeleton } from '@/components/ui/skeleton'
import i18n from '@/i18n'
import { BlockStack, Box, Card, SkeletonBodyText } from '@shopify/polaris'
import { createFileRoute } from '@tanstack/react-router'
import { atom } from 'jotai'

export const pageScope = 'brand-style/growth-tools'
export const SubmitErrorAtom = atom<any[]>([])

export const Route = createFileRoute('/brand-style/growth-tools')({
  loader: async () => {
    await i18n.loadNamespaces('brand-style/tools')
    const { data } = await tools$.getLabelSetting()
    return data
  },
  pendingMs: 200,
  pendingComponent: () => <GrowthToolsSkeleton />,
  gcTime: 0,
})

export function GrowthToolsSkeleton() {
  return (
    <>
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="top-4 w-full lg:sticky lg:flex-[0_0_240px]">
          <Card>
            <BlockStack gap="200">
              <SkeletonBodyText lines={8} />
            </BlockStack>
          </Card>
        </div>
        <Box width="100%">
          <DesignSkeleton />
        </Box>
      </div>
    </>
  )
}
